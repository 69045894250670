import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Svg from "../../common/Svg";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FormFields from './FormFields';


const TERMS = {
    "language_code": "22",
    "label": "Sum",
    "name": "TANDC",
    "options": {
        "required": false
    },
    type: 2,
}

const SUBMITBTN = {
    "language_code": "22",
    "label": "label",
    "name": "SUBMIT",
    "options": {
        "alignment": "Center"
    },
    type: 3,
}

const INPUT = {
    "user": "b5595410-61d6-11ee-a91a-7744900c38ce",
    "survey_id": "a5492540-94e5-11ee-9522-b17b9abc079c",
    "language_code": "22",
    "label": "label",
    "name": "name_276",
    "options": {
        "0": {
            "type": "text",
            "required": false,
            "placeholder": "Placeholder"
        }
    },
    "order": 5,
    "type": 1
}
const data = [
    // terms and conditions
    {
        "language_code": "22",
        "label": "Sum",
        "name": "TANDC",
        "options": {
            "required": false
        },
        type: 2,
    },
    // submit button
    {
        "language_code": "22",
        "label": "label",
        "name": "SUBMIT",
        "options": {
            "alignment": "Center"
        },
        type: 3,
    },
    // text field
    {
        "user": "b5595410-61d6-11ee-a91a-7744900c38ce",
        "survey_id": "a5492540-94e5-11ee-9522-b17b9abc079c",
        "language_code": "22",
        "label": "label",
        "name": "name_276",
        "options": {
            "0": {
                "type": "text",
                "required": false,
                "placeholder": "Placeholder"
            }
        },
        "order": 5,
        "type": 1
    },
    // textarea field
    {
        "user": "b5595410-61d6-11ee-a91a-7744900c38ce",
        "survey_id": "a5492540-94e5-11ee-9522-b17b9abc079c",
        "language_code": "22",
        "label": "label",
        "name": "name_938",
        "options": {
            "required": false,
            "placeholder": "placeholder"
        },
        "order": 6,
        "type": 4
    },
    // text field email
    {
        "user": "b5595410-61d6-11ee-a91a-7744900c38ce",
        "survey_id": "a5492540-94e5-11ee-9522-b17b9abc079c",
        "language_code": "22",
        "label": "label",
        "name": "name_146",
        "options": {
            "0": {
                "type": "email",
                "required": false,
                "placeholder": "Placeholder"
            }
        },
        "order": 7,
        "type": 1
    },
    // drop down 
    {
        "user": "b5595410-61d6-11ee-a91a-7744900c38ce",
        "survey_id": "a5492540-94e5-11ee-9522-b17b9abc079c",
        "language_code": "22",
        "label": "label",
        "name": "name_290",
        "options": {
            "option": {
                "opt1": "Option 1",
                "opt2": "Option 2"
            },
            "required": false,
            "placeholder": "placeholder"
        },
        "order": 8,
        "type": 3
    },
    // text field number
    {
        "user": "b5595410-61d6-11ee-a91a-7744900c38ce",
        "survey_id": "a5492540-94e5-11ee-9522-b17b9abc079c",
        "language_code": "22",
        "label": "label",
        "name": "name_580",
        "options": {
            "0": {
                "type": "number",
                "required": false,
                "placeholder": "Placeholder"
            }
        },
        "order": 9,
        "type": 1
    },
    // text field - check box
    {
        "user": "b5595410-61d6-11ee-a91a-7744900c38ce",
        "survey_id": "a5492540-94e5-11ee-9522-b17b9abc079c",
        "language_code": "22",
        "label": "label",
        "name": "name_769",
        "options": {
            "0": {
                "type": "checkbox",
                "value": "Option1",
                "required": false
            },
            "1": {
                "type": "checkbox",
                "value": "Option2",
                "required": false
            }
        },
        "order": 10,
        "type": 1
    },
    // text field - radio button
    {
        "user": "b5595410-61d6-11ee-a91a-7744900c38ce",
        "survey_id": "a5492540-94e5-11ee-9522-b17b9abc079c",
        "language_code": "22",
        "label": "label",
        "name": "name_122",
        "options": {
            "0": {
                "type": "radio",
                "value": "Option1",
                "required": false
            },
            "1": {
                "type": "radio",
                "value": "Option2",
                "required": false
            }
        },
        "order": 11,
        "type": 1
    }
]

const FormModal = ({
    teleportData,
    onSave,
    show,
    handleClose,
}) => {

    const [headText, setHeadText] = useState("");
    const [text, setText] = useState("");
    const [terms, setTerms] = useState(TERMS);
    const [btn, setBtn] = useState(SUBMITBTN);
    const [formFields, setFormFields] = useState([INPUT]);

    useEffect(() => {

        setHeadText(teleportData?.widget_details?.headText || "");
        setText(teleportData?.widget_details?.text || "");
        setTerms(teleportData?.widget_details?.terms || TERMS);
        setBtn(teleportData?.widget_details?.submitBtn || SUBMITBTN);
        setFormFields(teleportData?.widget_details?.formFields || [INPUT]);

    }, [teleportData]);

    const handleSave = () => {
        const widgetDetails = {
            headText: headText,
            text: text,
            formFields: formFields,
            terms: terms,
            submitBtn: btn,
        }
        onSave(widgetDetails);
        handleClose();
    }


    return (
        <div>
            <Modal show={show === "formModal"}
                onHide={handleClose}
                className="customvar-modal modal fade fileUpload uploadMedia"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-details-width"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="widget-code">
                        <div className="heading">
                            <h4>Edit Widget</h4>
                        </div>
                        <div className="d-flex text-image-widget text-video-widget align-items-start">
                            <div className="img-text-wrap">
                                <div className="form-widget-wrapper">
                                    <div className="text">
                                        <h3>{headText}</h3>
                                        <p>{text}</p>
                                    </div>
                                    <div className="form-inner">
                                        <div className="form-group mb-3">
                                            <label htmlFor="field1" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="field1" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="field2" className="form-label">Email</label>
                                            <input type="email" className="form-control" id="field2" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="field3" className="form-label">Message</label>
                                            <textarea className="form-control" id="field3" rows={3} defaultValue={""} />
                                        </div>
                                        <div className="mb-3 form-check text-center">
                                            <input type="checkbox" className="form-check-input" id="terms" />
                                            <label className="form-check-label" htmlFor="terms">{terms.name}</label>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary">{btn.name}</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="flex-grow-1 widget-fields form-view">

                                <div className="form-group mb-3">
                                    <div className="label">Heading</div>
                                    <input type="text" name="" className="form-control"
                                        placeholder="Enter your heading here"
                                        value={headText}
                                        onChange={(e) => setHeadText(e.target.value)}
                                    />
                                </div>
                                <div class="form-group mb-3">
                                    <label for="field3" class="form-label">Description</label>
                                    <textarea class="form-control" id="field3" rows="3"
                                        placeholder="Enter your description here"
                                        value={text}
                                        onChange={(e) => setText(e.target.value)}
                                    ></textarea>
                                </div>
                                <div className="slide-wrapper">
                                    {
                                        formFields.map((field, index) =>
                                            <FormFields
                                                field={field}
                                                setFormFields={setFormFields}
                                                index={index} />)
                                    }

                                    <div className="slide-list mb-3">
                                        <div className="slide-title">
                                            Text filed
                                        </div>
                                        <div className="field-wrapper d-flex align-items-center">
                                            <div className="field-inner flex-grow-1 pe-3">
                                                <div className="slide-inner mb-0">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="label">Label </div>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" id="switch1" />
                                                                        <label className="form-check-label" htmlFor="switch1"></label>
                                                                    </div>


                                                                </div>
                                                                <input type="text" name="" className="form-control" placeholder="Full name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">
                                                                <div className="label">Placeholder</div>
                                                                <input type="text" name="" className="form-control" placeholder="Enter your full name" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="more-setting">
                                                        <button className="btn more-setting-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#setting1" aria-expanded="false" aria-controls="setting1">
                                                            More settings</button>
                                                        <div className="collapse" id="setting1">
                                                            <div className="setting-inner">
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group mb-3">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="label">Validation </div>
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" id="switch11" />
                                                                                    <label className="form-check-label" htmlFor="switch11"></label>
                                                                                </div>
                                                                            </div>
                                                                            <select className="form-select">
                                                                                <option value={1}>Contains Validations</option>
                                                                                <option value={2}>Doesn’t contains</option>
                                                                            </select>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group mb-3">
                                                                            <div className="label">Enter characters/formula</div>
                                                                            <input type="text" name="" className="form-control" placeholder="Enter text" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <div className="label">Error message</div>
                                                                            <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <div className="d-flex">
                                                                                <div className="label me-2">Is required </div>
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" id="switch12" />
                                                                                    <label className="form-check-label" htmlFor="switch12"></label>
                                                                                </div>
                                                                            </div>
                                                                            <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                            <div className="remove-button"><Svg name="trash-icon" /></div>
                                        </div>
                                    </div>

                                    <div className="slide-list mb-3">
                                        <div className="slide-title">
                                            Email
                                        </div>
                                        <div className="field-wrapper d-flex align-items-center">
                                            <div className="field-inner flex-grow-1 pe-3">
                                                <div className="slide-inner mb-0">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="label">Label </div>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" id="switch2" />
                                                                        <label className="form-check-label" htmlFor="switch2"></label>
                                                                    </div>
                                                                </div>
                                                                <input type="text" name="" className="form-control" placeholder="Email" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">
                                                                <div className="label">Placeholder</div>
                                                                <input type="text" name="" className="form-control" placeholder="Enter your email" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="more-setting">
                                                        <button className="btn more-setting-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#setting2" aria-expanded="false" aria-controls="setting2">
                                                            More settings</button>
                                                        <div className="collapse" id="setting2">
                                                            <div className="setting-inner">
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group mb-3">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="label">Validation </div>
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" id="switch21" />
                                                                                    <label className="form-check-label" htmlFor="switch21"></label>
                                                                                </div>
                                                                            </div>
                                                                            <select className="form-select">
                                                                                <option value={1}>Contains Validations</option>
                                                                                <option value={2}>Doesn’t contains</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group mb-3">
                                                                            <div className="label">Enter characters/formula</div>
                                                                            <input type="text" name="" className="form-control" placeholder="Enter text" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <div className="label">Error message</div>
                                                                            <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <div className="d-flex">
                                                                                <div className="label me-2">Is required </div>
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" id="switch22" />
                                                                                    <label className="form-check-label" htmlFor="switch22"></label>
                                                                                </div>
                                                                            </div>
                                                                            <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                            <div className="remove-button"><Svg name="trash-icon" /></div>
                                        </div>
                                    </div>

                                    <div className="slide-list mb-3">
                                        <div className="slide-title">
                                            Dropdown
                                        </div>
                                        <div className="field-wrapper d-flex align-items-center">
                                            <div className="field-inner flex-grow-1 pe-3">
                                                <div className="slide-inner mb-0">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group mb-3">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="label">Label </div>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" id="switch3" />
                                                                        <label className="form-check-label" htmlFor="switch3"></label>
                                                                    </div>
                                                                </div>
                                                                <input type="text" name="" className="form-control" placeholder="Email" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group mb-3">
                                                                <div className="label">Options</div>
                                                                <div class="mb-2 d-flex align-items-center">
                                                                    <div class="flex-grow-1 pe-3">
                                                                        <input type="text" name="" className="form-control" placeholder="Option 1" />
                                                                    </div>
                                                                    <div class="action-button">
                                                                        <button class="action-btn remove">
                                                                            <Svg name="option-remove-icon" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-2 d-flex align-items-center">
                                                                    <div class="flex-grow-1 pe-3">
                                                                        <input type="text" name="" className="form-control" placeholder="Option 2" />
                                                                    </div>
                                                                    <div class="action-button">
                                                                        <button class="action-btn remove">
                                                                            <Svg name="option-remove-icon" />
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <div class="mb-2 d-flex align-items-center">
                                                                    <div class="flex-grow-1 pe-3">
                                                                        <input type="text" name="" className="form-control" placeholder="Option 3" />
                                                                    </div>
                                                                    <div class="action-button">
                                                                        <button class="action-btn add">
                                                                            <Svg name="option-add-icon" />
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="more-setting">
                                                        <button className="btn more-setting-btn collapsed" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#setting3" aria-expanded="false" aria-controls="setting3">
                                                            More settings</button>
                                                        <div className="collapse" id="setting3">
                                                            <div className="setting-inner">
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group mb-3">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="label">Validation </div>
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" id="switch31" />
                                                                                    <label className="form-check-label" htmlFor="switch31"></label>
                                                                                </div>
                                                                            </div>
                                                                            <select className="form-select">
                                                                                <option value={1}>Contains Validations</option>
                                                                                <option value={2}>Doesn’t contains</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group mb-3">
                                                                            <div className="label">Enter characters/formula</div>
                                                                            <input type="text" name="" className="form-control" placeholder="Enter text" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <div className="label">Error message</div>
                                                                            <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group mb-3">
                                                                            <div className="d-flex">
                                                                                <div className="label me-2">Is required </div>
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" id="switch32" />
                                                                                    <label className="form-check-label" htmlFor="switch32"></label>
                                                                                </div>
                                                                            </div>
                                                                            <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                            <div className="remove-button"><Svg name="trash-icon" /></div>
                                        </div>
                                    </div>


                                    <div className="slide-list mb-3">
                                        <div className="dropdown add-field">
                                            <button className="btn add-field-button" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <Svg name="plus" /> Add field
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" href="#">Text</a></li>
                                                <li><a className="dropdown-item" href="#">Email</a></li>
                                                <li><a className="dropdown-item" href="#">Text area </a></li>
                                                <li><a className="dropdown-item" href="#">Dropdown </a></li>
                                                <li><a className="dropdown-item" href="#">Number </a></li>
                                                <li><a className="dropdown-item" href="#">Checkbox </a></li>
                                                <li><a className="dropdown-item" href="#">Radio button </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <div className="label">Terms And Condition</div>
                                    <input type="text" name="termsAndCondition" className="form-control"
                                        placeholder="Enter your terms and condition here"
                                        value={terms.name}
                                        onChange={(e) => setTerms({ ...terms, name: e.target.value })}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <div className="label">Button Text</div>
                                    <input type="text" name="termsAndCondition" className="form-control"
                                        placeholder="Enter your button text here"
                                        value={btn.name}
                                        onChange={(e) =>
                                            setBtn({
                                                ...btn,
                                                name: e.target.value
                                            })
                                        }
                                    />
                                </div>
                                <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                                    <button type="button" className="btn btn-primary" onClick={handleSave}>Save widget</button>
                                    <button type="button" className="btn btn-bordered color-cancel ms-3" onClick={handleClose}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default FormModal