import React from "react";
import Svg from "./Svg";
import { Link, useNavigate } from "react-router-dom";

export default function CreateEvent({ closeModal }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="modal-content">
        <div className="modal-body">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              closeModal();
            }}
          />
          <div className="formstyle">
            <div className="row">
              <div className="col-lg-6 col-sm-6 mb-3 mb-lg-0">
                <div
                  className="event-wrapper"
                  onClick={() => {
                    navigate("/events/schedule");
                    closeModal();
                  }}
                >
                  <div className="icon-wrap">
                    <Svg name="add-meeting-icon" />
                  </div>
                  <div className="text-wrap">
                    <h4>Meeting</h4>
                    <p>
                      Hosted experience with multiplayer avatar-based video
                      collaboration
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-sm-6 mb-3 mb-lg-0">
                <div
                  className="event-wrapper"
                  onClick={() => {
                    navigate("/immersive-guided/schedule");
                    closeModal();
                  }}
                >
                  <div className="icon-wrap">
                    <Svg name="add-guided-icon" />
                  </div>
                  <div className="text-wrap">
                    <h4>Immersive (Guided) </h4>
                    <p>
                      Hosted experience with single player video collaboration
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-6 col-sm-6 mb-3 mb-lg-0">
                <div
                  className="event-wrapper"
                  onClick={() => {
                    navigate("/immersive-self-guided/schedule");
                    closeModal();
                  }}
                >
                  <div className="icon-wrap">
                    <Svg name="add-self-guided-icon" />
                  </div>
                  <div className="text-wrap">
                    <h4>Immersive (Self-guided)</h4>
                    <p>Users can explore on their own</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-sm-6 mb-3 mb-lg-0">
                <div
                  className="event-wrapper"
                  onClick={() => {
                    navigate("/vr-experience/schedule");
                    closeModal();
                  }}
                >
                  <div className="icon-wrap">
                    <Svg name="add-vr-icon" />
                  </div>
                  <div className="text-wrap">
                    <h4>VR Experience</h4>
                    <p>Users can explore experiences via VR headset</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
