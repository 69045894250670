import React from "react";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "./views/authentication/ForgotPassword";
import Login from "./views/authentication/Login";
import OTP from "./views/authentication/OTP";
import PrivacyPolicies from "./views/content-pages/PrivacyPolicies";
import Terms from "./views/content-pages/Terms";
import Dashboard from "./views/dashobard/Dashboard";
import List from "./views/events/List";
import Schedule from "./views/events/Schedule";
import Notifications from "./views/settings/Notifications";
import Profile from "./views/settings/Profile";
import CreateTeam from "./views/settings/teams/Create";
import EditTeam from "./views/settings/teams/Edit";
import Teams from "./views/settings/teams/Teams";
import UserPermissions from "./views/settings/user-permissions/UserPermissions";
import UserRoles from "./views/settings/user-roles/UserRoles";
import ChooseTemplate from "./views/events/ChooseTemplate";
import Share from "./views/events/Share";
import Media from "./views/media/Media";
import Analytics from "./views/analytics/Index";
import CookiePolicy from "./views/content-pages/CookiePolicy";
import ResetPassword from "./views/authentication/ResetPassword";
import Experiences from "./views/experiences/Index";
import CreateExperience from "./views/experiences/CreateExperience";
import EditExperience from "./views/experiences/EditExperience";
import InviteParticipants from "./views/events/InviteParticipants";
import ProtectedLayout from "./ProtectedLayout";
import PublicLayout from "./PublicLayout";
import SelectMediaspot from "./views/experiences/add-media/SelectMediaspot";
import AddMedia from "./views/experiences/add-media/AddMedia";
import Reorder from "./views/experiences/Reorder";
import CustomizeTemplateArea from "./views/common/CustomizeTemplateArea";

import VrList from "./views/vr-experience/List";
import VrSchedule from "./views/vr-experience/Schedule";
import VrChooseTemplate from "./views/vr-experience/ChooseTemplate";
import VrInviteParticipants from "./views/vr-experience/InviteParticipants";
import VrShare from "./views/vr-experience/Share";


import IGList from "./views/immersive-guided/List";
import IGSchedule from "./views/immersive-guided/Schedule";
import IGChooseTemplate from "./views/immersive-guided/ChooseTemplate";
import IGInviteParticipants from "./views/immersive-guided/InviteParticipants";
import IGShare from "./views/immersive-guided/Share";


import ISGList from "./views/immersive-self-guided/List";
import ISGSchedule from "./views/immersive-self-guided/Schedule";
import ISGChooseTemplate from "./views/immersive-self-guided/ChooseTemplate";
import ISGInviteParticipants from "./views/immersive-self-guided/InviteParticipants";
import ISGShare from "./views/immersive-self-guided/Share";
import ISGCreateForm from "./views/immersive-self-guided/CreateForm";
import AddAnnotations from "./views/experiences/add-media/AddAnnotations";

import Metaspaces from "./views/metaspaces/TemplatesList";
import CreateMetaspace from "./views/metaspaces/CreateMetaspace";
import EditMetaspace from "./views/metaspaces/EditMetaspace";

function App() {

  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route exact path="/" element={<Login />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route
          exact
          path="/forgot-password"
          element={<ForgotPassword />}
        ></Route>
        <Route exact path="/otp" element={<OTP />}></Route>
        <Route exact path="/reset-password" element={<ResetPassword />}></Route>
        <Route exact path="/terms" element={<Terms />}></Route>
        <Route exact path="/cookie-policy" element={<CookiePolicy />}></Route>
        <Route
          exact
          path="/privacy-policies"
          element={<PrivacyPolicies />}
        ></Route>
      </Route>

      <Route element={<ProtectedLayout />}>
        <Route exact path="/settings/profile" element={<Profile />} />
        <Route exact path="/settings/notification" element={<Notifications />} />
        <Route exact path="/settings/user-roles" element={<UserRoles />} />
        <Route exact path="/settings/users" element={<UserPermissions />} />
        <Route exact path="/settings/teams/list" element={<Teams />} />
        <Route exact path="/settings/teams/create" element={<CreateTeam />} />
        <Route exact path="/settings/teams/edit" element={<EditTeam />} />

        <Route exact path="/dashboard" element={<Dashboard />} />

        <Route exact path="/analytics" element={<Analytics />} />

        <Route exact path="/events/list" element={<List />} />
        <Route exact path="/events/schedule" element={<Schedule />} />
        <Route exact path="/events/select-experience" element={<ChooseTemplate />} />
        <Route exact path="/events/invite-participants" element={<InviteParticipants />} />
        <Route exact path="/events/share" element={<Share />} />


        <Route exact path="/vr-experience/list" element={<VrList />} />
        <Route exact path="/vr-experience/schedule" element={<VrSchedule />} />
        <Route exact path="/vr-experience/select-experience" element={<VrChooseTemplate />} />
        <Route exact path="/vr-experience/invite-participants" element={<VrInviteParticipants />} />
        <Route exact path="/vr-experience/share" element={<VrShare />} />


        <Route exact path="/immersive-guided/list" element={<IGList />} />
        <Route exact path="/immersive-guided/schedule" element={<IGSchedule />} />
        <Route exact path="/immersive-guided/select-experience" element={<IGChooseTemplate />} />
        <Route exact path="/immersive-guided/invite-participants" element={<IGInviteParticipants />} />
        <Route exact path="/immersive-guided/share" element={<IGShare />} />


        <Route exact path="/immersive-self-guided/list" element={<ISGList />} />
        <Route exact path="/immersive-self-guided/schedule" element={<ISGSchedule />} />
        <Route exact path="/immersive-self-guided/select-experience" element={<ISGChooseTemplate />} />
        <Route exact path="/immersive-self-guided/invite-participants" element={<ISGInviteParticipants />} />
        <Route exact path="/immersive-self-guided/create-form" element={<ISGCreateForm />} />
        <Route exact path="/immersive-self-guided/share" element={<ISGShare />} />



        <Route exact path="/media" element={<Media />} />
        <Route exact path="/metaspaces" element={<Metaspaces />} />
        <Route exact path="/metaspaces/create-Metaspace" element={<CreateMetaspace />} />
        <Route exact path="/metaspaces/edit-Metaspace" element={<EditMetaspace />} />

        <Route exact path="/experiences" element={<Experiences />} />
        <Route exact path="/experiences/create-experience" element={<CreateExperience />} />
        <Route exact path="/experiences/edit-experience" element={<EditExperience />} />
        <Route exact path="/experiences/create-experience/select-hotspot" element={<SelectMediaspot />} />
        <Route exact path="/experiences/create-experience/select-hotspot/add-annotations" element={<AddAnnotations />} />
        <Route exact path="/experiences/create-experience/select-hotspot/add-media" element={<AddMedia />} />
        <Route exact path="/experiences/create-experience/select-hotspot/add-media/reorder" element={<Reorder />} />

        <Route exact path="/experiences/edit-experience/select-hotspot" element={<SelectMediaspot />} />
        <Route exact path="/experiences/edit-experience/select-hotspot/add-annotations" element={<AddAnnotations />} />
        <Route exact path="/experiences/edit-experience/select-hotspot/add-media" element={<AddMedia />} />
        <Route exact path="/experiences/edit-experience/select-hotspot/add-media/reorder" element={<Reorder />} />

      </Route>
    </Routes>
  );
}

export default App;
