import React, { useEffect, useState } from 'react';
import imgText from "../../../assets/images/image-text.jpg";
import FileUploadWidget from '../add-media/FileUploadWidget';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ImageTextModal = ({
    teleportData,
    // handleClose,
    // setTemplates,
    // selectedSpotAreaId,
    // teleportName,
    // templatesData,
    onSave,
    show,
    handleClose,
}) => {

    // const [templates, setTemplates] = useState({...templatesData});
    const [text, setText] = useState("");
    const [primBtnText, setPrimBtnText] = useState("");
    const [primBtnUrl, setPrimBtnUrl] = useState("");
    const [secBtnText, setSecBtnText] = useState("");
    const [secBtnUrl, setSecBtnUrl] = useState("");
    const [widgetFile, setWidgetFile] = useState({});
    const [file, setFile] = useState(null);

    useEffect(() => {
        setPrimBtnText(teleportData?.widget_details?.prim_btn_text || "");
        setPrimBtnUrl(teleportData?.widget_details?.prim_btn_url || "");
        setSecBtnText(teleportData?.widget_details?.sec_btn_text || "");
        setSecBtnUrl(teleportData?.widget_details?.sec_btn_url || "");
        setText(teleportData?.widget_details?.text || "");
        setWidgetFile(teleportData?.widget_details?.file || {});
        setFile(teleportData?.widget_details?.file || null);

        console.log(teleportData?.widget_details?.file);
    }, [teleportData]);

    // const handleModalInputChange = (event, key) => {
    //     const newData = event.target.value;
    //     setTemplates((prevTemplates) => {
    //         const selectedTemplate = prevTemplates[selectedSpotAreaId]?.teleport_points[teleportName];

    //         if (selectedTemplate) {
    //             selectedTemplate.widget_details = {
    //                 ...selectedTemplate.widget_details,
    //                 [key]: newData,
    //             };
    //         }

    //         return { ...prevTemplates };
    //     });
    // };

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const closeModal = () => {
        const modalId = teleportData.widget_type.modal;
        window.bootstrap.Modal.getOrCreateInstance(document.getElementById(modalId)).hide();
    };

    const handleSave = () => {
        const widgetDetails = {
            text,
            prim_btn_text: primBtnText,
            prim_btn_url: primBtnUrl,
            sec_btn_text: secBtnText,
            sec_btn_url: secBtnUrl,
            file: widgetFile,
        }
        onSave(widgetDetails);
        handleClose();
    }

    

    return (
        <div>
            <Modal show={show === "imageTextModal"}
                onHide={handleClose}
                className="customvar-modal modal fade fileUpload uploadMedia"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-details-width"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="widget-code">
                        <div className="heading">
                            <h4>Edit Widget</h4>
                        </div>
                        <div className="d-flex text-image-widget align-items-start">
                            <div className="img-text-wrap">
                                <div className="img-rap">
                                    <img src={widgetFile?.link || imgText} alt="" className="img-fluid" />
                                </div>
                                <div className="text-wrap">
                                    {/* <h4>Nulla ut iaculis augue, at lacinia velit. Maecenas a risus</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                    <p>Sed ex mi, imperdiet ultricies consectetur tempor</p>
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet consectetur</li>
                                        <li>Aadipisicing elit.</li>
                                        <li>Provident aut vero ipsum temporibus</li>
                                    </ul> */}
                                    {/* <p>{text}</p> */}
                                    <div dangerouslySetInnerHTML={{__html: text}} className='editor'></div>
                                </div>
                            </div>
                            <div className="flex-grow-1 widget-fields">
                                <FileUploadWidget
                                    currentMediaSpotId={null}
                                    selectedSpotAreaId={null}
                                    currentMediaSpotData={widgetFile}
                                    changeMediaSpotData={(data) => {
                                        setWidgetFile(data);
                                        console.log(data);
                                    }}
                                    closeModal={handleClose}
                                    isOpenModal={true}
                                />
                                <div className="form-group mb-3">
                                    <div className="label">Insert text here</div>
                                    <div className="editor-wrapper">
                                        {/* <textarea
                                            rows={4}
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                        >

                                        </textarea> */}
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={text}
                                            config={{
                                                removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table", "TableToolbar"]
                                            }}
                                            onChange={(event, editor) => {
                                                setText(editor.getData());
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <div className="label button-label" data-bs-toggle="collapse" data-bs-target="#collapsePrimary" aria-expanded="false" aria-controls="collapsePrimary">Primary button</div>
                                            <div className="collapse" id="collapsePrimary">
                                                <div className="card card-body buttons-body">
                                                    <div className="field-wrapper">
                                                        <div className="row mb-3">
                                                            <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">Text on button</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" id="inputEmail3"
                                                                    value={primBtnText}
                                                                    onChange={(e) => setPrimBtnText(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">URL</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" id="inputEmail3"
                                                                    value={primBtnUrl}
                                                                    onChange={(e) => setPrimBtnUrl(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <div className="label button-label" data-bs-toggle="collapse" data-bs-target="#collapseSecondary" aria-expanded="false" aria-controls="collapseSecondary">Secondary button</div>
                                            <div className="collapse" id="collapseSecondary">
                                                <div className="card card-body buttons-body">
                                                    <div className="field-wrapper">
                                                        <div className="row mb-3">
                                                            <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">Text on button</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" id="inputEmail3"
                                                                    value={secBtnText}
                                                                    onChange={(e) => setSecBtnText(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">URL</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" id="inputEmail3"
                                                                    value={secBtnUrl}
                                                                    onChange={(e) => setSecBtnUrl(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                                    <button type="button" className="btn btn-primary" onClick={handleSave} >Save widget</button>
                                    <button type="button" className="btn btn-bordered color-cancel ms-3" onClick={handleClose}>Cancel</button>
                                </div>

                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default ImageTextModal