import React, { useEffect, useState } from 'react';
import imgText from "../../../assets/images/image-text.jpg";
import FileUploadWidget from '../add-media/FileUploadWidget';
import Modal from 'react-bootstrap/Modal';
import Svg from "../../common/Svg";
import Slider from "react-slick";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const imgSlider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const ProductModal = ({
    teleportData,
    onSave,
    show,
    handleClose,
}) => {

    const [headingText, setHeadingText] = useState("");
    const [text, setText] = useState("");
    const [primBtnText, setPrimBtnText] = useState("");
    const [primBtnUrl, setPrimBtnUrl] = useState("");
    const [secBtnText, setSecBtnText] = useState("");
    const [secBtnUrl, setSecBtnUrl] = useState("");
    const [widgetFile, setWidgetFile] = useState({});
    const [file, setFile] = useState(null);

    useEffect(() => {
        setPrimBtnText(teleportData?.widget_details?.prim_btn_text || "");
        setPrimBtnUrl(teleportData?.widget_details?.prim_btn_url || "");
        setSecBtnText(teleportData?.widget_details?.sec_btn_text || "");
        setSecBtnUrl(teleportData?.widget_details?.sec_btn_url || "");
        setText(teleportData?.widget_details?.text || "");
        setWidgetFile(teleportData?.widget_details?.file || {});
        setFile(teleportData?.widget_details?.file || null);
        setHeadingText(teleportData?.widget_details?.headText || null);

        console.log(teleportData?.widget_details?.file);
    }, [teleportData]);


    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };


    const handleSave = () => {
        const widgetDetails = {
            text,
            prim_btn_text: primBtnText,
            prim_btn_url: primBtnUrl,
            sec_btn_text: secBtnText,
            sec_btn_url: secBtnUrl,
            file: widgetFile,
            headText: headingText,
        }
        onSave(widgetDetails);
        handleClose();
    }


    return (
        <div>
            <Modal show={show === "productModal"}
                onHide={handleClose}
                className="customvar-modal modal fade fileUpload uploadMedia"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-details-width"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="widget-code">
                        <div className="heading">
                            <h4>Edit Widget</h4>
                        </div>
                        <div className="d-flex text-image-widget product-view align-items-start">
                            <div className="img-text-wrap">
                                <div className="product-wrapper">
                                    <div className="cross-button">
                                        <Svg name="cross-button" />
                                    </div>
                                    <div className="img-wrapper">
                                        <Slider {...imgSlider}>
                                            <div>
                                                <div className="img-in">
                                                    <img src={imgText} alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="img-in">
                                                    <img src={imgText} alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="img-in">
                                                    <img src={imgText} alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                    <div className="product-text">
                                        <div className="main-text">
                                            <h3>{headingText}</h3>
                                            <div className="price-rating">
                                                <h5>$55 </h5>
                                                <div className="rating">
                                                    <div className="stars">
                                                        <div className="star rated"></div>
                                                        <div className="star rated"></div>
                                                        <div className="star rated"></div>
                                                        <div className="star rated-half"></div>
                                                        <div className="star"></div>
                                                    </div>
                                                    <div className="reviews">(3.5 stars) • 10 ratings</div>
                                                </div>
                                            </div>
                                            <div className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut </div>
                                        </div>
                                        <div className="variant">
                                            <div className="variant-heading">Variant</div>
                                            <ul className="nav nav-tabs" id="productTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="product-tab-1" data-bs-toggle="tab" data-bs-target="#product-t-1" type="button" role="tab" aria-controls="product-t-1" aria-selected="true">Option One</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="product-tab-2" data-bs-toggle="tab" data-bs-target="#product-t-2" type="button" role="tab" aria-controls="product-t-2" aria-selected="false">Option Two</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="product-tab-3" data-bs-toggle="tab" data-bs-target="#product-t-3" type="button" role="tab" aria-controls="product-t-3" aria-selected="false">Option Three</button>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="productTabContent">
                                                <div className="tab-pane fade show active" id="product-t-1" role="tabpanel" aria-labelledby="product-tab-1">
                                                    <div className="tab-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio autem ab labore in optio rerum, doloribus illo aliquid nemo molestiae incidunt saepe perspiciatis commodi cumque a quas. Illo, alias!</div>
                                                </div>
                                                <div className="tab-pane fade" id="product-t-2" role="tabpanel" aria-labelledby="product-tab-2">
                                                    <div className="tab-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio autem ab labore in optio rerum, doloribus illo aliquid nemo molestiae incidunt saepe perspiciatis commodi cumque a quas. Illo, alias!</div>
                                                </div>
                                                <div className="tab-pane fade" id="product-t-3" role="tabpanel" aria-labelledby="product-tab-3">
                                                    <div className="tab-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio autem ab labore in optio rerum, doloribus illo aliquid nemo molestiae incidunt saepe perspiciatis commodi cumque a quas. Illo, alias!</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="accordion accordion-product-wrp" id="accordionProduct">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="accordionProductHeading1">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordionProductCollapse1" aria-expanded="true" aria-controls="accordionProductCollapse1">
                                                        Accordion Item #1
                                                    </button>
                                                </h2>
                                                <div id="accordionProductCollapse1" className="accordion-collapse collapse show" aria-labelledby="accordionProductHeading1" data-bs-parent="#accordionProduct">
                                                    <div className="accordion-body">
                                                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="accordionProductHeading2">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionProductCollapse2" aria-expanded="false" aria-controls="accordionProductCollapse2">
                                                        Accordion Item #2
                                                    </button>
                                                </h2>
                                                <div id="accordionProductCollapse2" className="accordion-collapse collapse" aria-labelledby="accordionProductHeading2" data-bs-parent="#accordionProduct">
                                                    <div className="accordion-body">
                                                        These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="accordionProductHeading3">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionProductCollapse3" aria-expanded="false" aria-controls="accordionProductCollapse3">
                                                        Accordion Item #3
                                                    </button>
                                                </h2>
                                                <div id="accordionProductCollapse3" className="accordion-collapse collapse" aria-labelledby="accordionProductHeading3" data-bs-parent="#accordionProduct">
                                                    <div className="accordion-body">
                                                        These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-wrap">
                                        <button className="btn btn-primary">Button</button>
                                        <button className="btn btn-bordered">Button</button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-1 widget-fields">
                                <FileUploadWidget
                                    currentMediaSpotId={null}
                                    selectedSpotAreaId={null}
                                    currentMediaSpotData={widgetFile}
                                    changeMediaSpotData={(data) => {
                                        setWidgetFile(data);
                                        console.log(data);
                                    }}
                                    closeModal={handleClose}
                                    isOpenModal={true}
                                />

                                <div className="slide-wrapper pb-2">
                                    <div className="slide-list mb-3">
                                        <div className="slide-title">Image slider</div>
                                        <div className="slide-inner mb-0 pt-2">
                                            <div class="form-text text-end mb-2 mt-0">Dimensions: 400 x 600px Supported format: PNG,JPEG,JPG</div>
                                            <div className="form-group ">

                                                <div className="img-list-wrapper">
                                                    <div className="label">Image 1</div>
                                                    <div class="d-flex align-items-center img-list">
                                                        <div class="flex-grow-1 pe-3">
                                                            <input className="form-control" type="file" id="image-1" />
                                                        </div>
                                                        <div class="action-button">
                                                            <button class="action-btn remove">
                                                                <Svg name="option-remove-icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="img-list-wrapper">
                                                    <div className="label">Image 2</div>
                                                    <div class="d-flex align-items-center img-list">
                                                        <div class="flex-grow-1 pe-3">
                                                            <input className="form-control" type="file" id="image-2" />
                                                        </div>
                                                        <div class="action-button">
                                                            <button class="action-btn remove">
                                                                <Svg name="option-remove-icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="img-list-wrapper">
                                                    <div className="label">Image 3</div>
                                                    <div class="d-flex align-items-center img-list">
                                                        <div class="flex-grow-1 pe-3">
                                                            <input className="form-control" type="file" id="image-3" />
                                                        </div>
                                                        <div class="action-button">
                                                            <button class="action-btn remove">
                                                                <Svg name="option-remove-icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="img-list-wrapper">
                                                    <div className="label">Image 4</div>
                                                    <div class="d-flex align-items-center img-list">
                                                        <div class="flex-grow-1 pe-3">
                                                            <input className="form-control" type="file" id="image-4" />
                                                        </div>
                                                        <div class="action-button">
                                                            <button class="action-btn add">
                                                                <Svg name="option-add-icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>


                                        </div>
                                    </div>
                                    <div className="slide-list mb-3">
                                        <div className="slide-title">Text content</div>
                                        <div className="slide-inner mb-0">
                                            <div className="form-group mb-3">
                                                <div className="label">Heading</div>
                                                <input type="text" name="" className="form-control" placeholder="Enter your heading here" 
                                                    value={headingText}
                                                    onChange={(e) => setHeadingText(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="label">Paragraph</div>
                                                <div className="editor-wrapper">
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={text}
                                                        config={{
                                                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table", "TableToolbar"]
                                                        }}
                                                        onChange={(event, editor) => {
                                                            setText(editor.getData());
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slide-list mb-3">
                                        <div className="slide-title">Tabs</div>
                                        <div className="tabs-list-wrapper brdr-top">
                                            <div className="form-group mb-3">
                                                <div className="label">Tab 1</div>
                                                <div class="d-flex align-items-center img-list">
                                                    <div class="flex-grow-1 pe-3">
                                                        <div className="slide-inner mb-0">
                                                            <div className="form-group mb-3">
                                                                <div className="label">Heading</div>
                                                                <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="label">Paragraph</div>
                                                                <div className="editor-wrapper">
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={text}
                                                                        config={{
                                                                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table", "TableToolbar"]
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            setText(editor.getData());
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="action-button">
                                                        <button class="action-btn remove">
                                                            <Svg name="option-remove-icon" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="label">Tab 2</div>
                                                <div class="d-flex align-items-center img-list">
                                                    <div class="flex-grow-1 pe-3">
                                                        <div className="slide-inner mb-0">
                                                            <div className="form-group mb-3">
                                                                <div className="label">Heading</div>
                                                                <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="label">Paragraph</div>
                                                                <div className="editor-wrapper">
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={text}
                                                                        config={{
                                                                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table", "TableToolbar"]
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            setText(editor.getData());
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="action-button">
                                                        <button class="action-btn remove">
                                                            <Svg name="option-remove-icon" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="label">Tab 3</div>
                                                <div class="d-flex align-items-center img-list">
                                                    <div class="flex-grow-1 pe-3">
                                                        <div className="slide-inner mb-0">
                                                            <div className="form-group mb-3">
                                                                <div className="label">Heading</div>
                                                                <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="label">Paragraph</div>
                                                                <div className="editor-wrapper">
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={text}
                                                                        config={{
                                                                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table", "TableToolbar"]
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            setText(editor.getData());
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="action-button">
                                                        <button class="action-btn add">
                                                            <Svg name="option-add-icon" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-button mb-3">
                                                <button className="add-slide-button">Add more tab</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slide-list mb-3">
                                        <div className="slide-title">Accordion</div>
                                        <div className="tabs-list-wrapper brdr-top">
                                            <div className="form-group mb-3">
                                                <div className="label">Accordion 1</div>
                                                <div class="d-flex align-items-center img-list">
                                                    <div class="flex-grow-1 pe-3">
                                                        <div className="slide-inner mb-0">
                                                            <div className="form-group mb-3">
                                                                <div className="label">Heading</div>
                                                                <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="label">Paragraph</div>
                                                                <div className="editor-wrapper">
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={text}
                                                                        config={{
                                                                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table", "TableToolbar"]
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            setText(editor.getData());
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="action-button">
                                                        <button class="action-btn remove">
                                                            <Svg name="option-remove-icon" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="label">Accordion 2</div>
                                                <div class="d-flex align-items-center img-list">
                                                    <div class="flex-grow-1 pe-3">
                                                        <div className="slide-inner mb-0">
                                                            <div className="form-group mb-3">
                                                                <div className="label">Heading</div>
                                                                <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="label">Paragraph</div>
                                                                <div className="editor-wrapper">
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={text}
                                                                        config={{
                                                                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table", "TableToolbar"]
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            setText(editor.getData());
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="action-button">
                                                        <button class="action-btn remove">
                                                            <Svg name="option-remove-icon" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="label">Accordion 3</div>
                                                <div class="d-flex align-items-center img-list">
                                                    <div class="flex-grow-1 pe-3">
                                                        <div className="slide-inner mb-0">
                                                            <div className="form-group mb-3">
                                                                <div className="label">Heading</div>
                                                                <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="label">Paragraph</div>
                                                                <div className="editor-wrapper">
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={text}
                                                                        config={{
                                                                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table", "TableToolbar"]
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            setText(editor.getData());
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="action-button">
                                                        <button class="action-btn add">
                                                            <Svg name="option-add-icon" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-button mb-3">
                                                <button className="add-slide-button">Add accordion</button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <div className="label button-label" data-bs-toggle="collapse" data-bs-target="#collapsePrimary" aria-expanded="false" aria-controls="collapsePrimary">Primary button</div>
                                            <div className="collapse" id="collapsePrimary">
                                                <div className="card card-body buttons-body">
                                                    <div className="field-wrapper">
                                                        <div className="row mb-3">
                                                            <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">Text on button</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" id="inputEmail3"
                                                                    value={primBtnText}
                                                                    onChange={(e) => setPrimBtnText(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">URL</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" id="inputEmail3"
                                                                    value={primBtnUrl}
                                                                    onChange={(e) => setPrimBtnUrl(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <div className="label button-label" data-bs-toggle="collapse" data-bs-target="#collapseSecondary" aria-expanded="false" aria-controls="collapseSecondary">Secondary button</div>
                                            <div className="collapse" id="collapseSecondary">
                                                <div className="card card-body buttons-body">
                                                    <div className="field-wrapper">
                                                        <div className="row mb-3">
                                                            <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">Text on button</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" id="inputEmail3"
                                                                    value={secBtnText}
                                                                    onChange={(e) => setSecBtnText(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">URL</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" id="inputEmail3"
                                                                    value={secBtnUrl}
                                                                    onChange={(e) => setSecBtnUrl(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                                    <button type="button" className="btn btn-primary" onClick={handleSave} >Save widget</button>
                                    <button type="button" className="btn btn-bordered color-cancel ms-3" onClick={handleClose}>Cancel</button>
                                </div>

                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default ProductModal