import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "../../common/DashboardHeader";
import DashboardSidebar from "../../common/DashboardSidebar";
import Svg from "../../common/Svg";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import {
    getExperienceDetail,
    getMediaSpotList,
    getSelectedMediaSpot,
    updateStructure,
} from "../../../actions/experience";
import merge from "lodash/merge";
import ContentLoader from "react-content-loader";
import ExperienceSubHeader from "../ExperienceSubHeader";
import Select from 'react-select';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import VideoTextModal from "../widget-modal/VideoTextModal";
import ImageTextModal from "../widget-modal/ImageTextModal";

import PdfTextModal from "../widget-modal/PdfTextModal";
import SliderImageModal from "../widget-modal/SliderImageModal";
import ProductModal from "../widget-modal/ProductModal";
import AccordianTextModal from "../widget-modal/AccordianTextModal";
import FormModal from "../widget-modal/FormModal";

const MediaType = [
    { value: '1', label: 'PDF', name: 'PDF', ext: "pdf", type: "document", modal: "pdfModal" },
    { value: '2', label: 'Text content', name: 'Text content', ext: "html", type: "html", modal: "imageTextModal" },
    { value: '3', label: 'Product card', name: 'Product card', ext: "html", type: "html", modal: "productModal" },
    { value: '4', label: 'Email sign-up', name: 'Email sign-up', ext: "html", type: "html", modal: "formModal" },
    { value: '5', label: 'Contact form', name: 'Contact form', ext: "html", type: "html", modal: "formModal" },
    { value: '6', label: 'Text with video', name: 'Text with video', ext: "mp4", type: "video", modal: "videoTextModal" },
    { value: '7', label: 'Text with Image', name: 'Text with Image', ext: "png", type: "image", modal: "imageTextModal" },
    { value: '8', label: 'Text with background', name: 'Text with background', ext: "png", type: "image", modal: "imageTextModal" },
    { value: '9', label: 'Card with content and button', name: 'Card with content and button', ext: "html", type: "html" },
    { value: '10', label: 'Text with accordian', name: 'Text with accordian', ext: "html", type: "html", modal: "accordianTextModal" },
    { value: '11', label: 'Small contact form', name: 'Small contact form', ext: "html", type: "html", modal: "formModal" },
    { value: '12', label: 'text and image with carousel', name: 'text and image with carousel', ext: "html", type: "html", modal: "sliderImageTextModal" },
];

const Loader = (props) => (
    <ContentLoader viewBox="0 0 100% 100%" height={600} width="100%" {...props}>
        <rect x="0" y="20" rx="0" ry="0" width="380" height="190" />
        <rect x="0" y="225" rx="0" ry="0" width="380" height="30" />
        <rect x="0" y="270" rx="0" ry="0" width="380" height="50" />
        <rect x="410" y="20" rx="0" ry="0" width="380" height="190" />
        <rect x="410" y="225" rx="0" ry="0" width="380" height="30" />
        <rect x="410" y="270" rx="0" ry="0" width="380" height="50" />
        <rect x="820" y="20" rx="0" ry="0" width="380" height="190" />
        <rect x="820" y="225" rx="0" ry="0" width="380" height="30" />
        <rect x="820" y="270" rx="0" ry="0" width="380" height="50" />
        <rect x="1220" y="20" rx="0" ry="0" width="380" height="190" />
        <rect x="1220" y="225" rx="0" ry="0" width="380" height="30" />
        <rect x="1220" y="270" rx="0" ry="0" width="380" height="50" />
    </ContentLoader>
);

export default function AddAnnotations() {

    const { state } = useLocation();
    const navigate = useNavigate();

    const [isFeatchingMediaspotDetail, setIsFeatchingMMediaspotDetail] =
        useState(true);

    const [isFetchingDetail, setIsFetchingDetail] = useState(false);
    const [isUploading, setIsUploading] = useState(true);
    const [isUpdatingStructure, setIsUpdatingStructure] = useState(false);

    const [experianceDetail, setExperianceDetail] = useState(null);
    const [spotAreas, setSpotAreas] = useState([]);
    const [selectedSpotAreaId, setSelectedSpotAreaId] = useState(null);

    const [templates, setTemplates] = useState({});

    const [annotationName, setAnnotationName] = useState("");
    const [annotationData, setAnnotationData] = useState({});
    const [modalType, setModalType] = useState(null);


    const fetchExperienceDetail = async (experienceId) => {
        setIsFetchingDetail(true);
        let dataToSend = {
            id: experienceId,
        };
        try {
            const res = await getExperienceDetail(dataToSend);
            if (res.statusCode === 200) {
                setExperianceDetail(res.data);
                setSpotAreas(res.data.templates);
                setSelectedSpotAreaId(res.data.templates[0].id);
            } else {
                toast.error(res.msg);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setIsFetchingDetail(false);
        }
    };

    const updateTemplates = (templateId, templateData) => {
        setTemplates((prevState) => {
            return {
                ...prevState,
                [templateId]: templateData,
            };
        });
    };

    const mergeTemplateData = (masterData, experienceData, templateId) => {
        if (typeof experienceData === "string") {
            experienceData = JSON.parse(experienceData);
        }
        // if has no data then clone master data else merge data
        if (
            !Object.prototype.hasOwnProperty.call(experienceData, "annotation_count")
        ) {
            return masterData;
        } else {
            // load alredy stored data other wise get data then load - because data might be modified by user
            if (Object.prototype.hasOwnProperty.call(templates, templateId)) {
                return templates[templateId];
            }
            // merge data here
            let mergedData = merge(masterData, experienceData);
            mergedData = {
                ...mergedData,
                media_count: Object.keys(mergedData.media_spots).length,
                teleport_count: Object.keys(mergedData.teleport_points).length,
                annotation_count: Object.keys(mergedData.annotation_points).length,
            };
            return mergedData;
        }
    };

    const getSelectedMediaspotList = async (templateId) => {
        return new Promise(async (resolve, reject) => {
            setIsFeatchingMMediaspotDetail(true);
            let dataToSend = {
                t_id: templateId,
            };
            try {
                const masterHotspot = await getMediaSpotList(dataToSend);
                if (masterHotspot.statusCode === 200) {
                    let dataToSend = {
                        id: state.experienceId,
                        t_id: templateId,
                    };
                    const experienceHotspot = await getSelectedMediaSpot(dataToSend);
                    if (experienceHotspot.statusCode === 200) {
                        let newTemplateData = mergeTemplateData(
                            masterHotspot.data[0].template_structure,
                            experienceHotspot.data.structure,
                            templateId
                        );
                        updateTemplates(templateId, newTemplateData);
                        resolve(newTemplateData);
                    } else {
                        toast.error(experienceHotspot.msg);
                        reject("no data");
                    }
                } else {
                    toast.error(masterHotspot.msg);
                    reject("no data");
                }
            } catch (error) {
                toast.error(error);
                reject("no data");
            } finally {
                setIsFeatchingMMediaspotDetail(false);
            }
        });
    };

    const changeSpotArea = async (areaId) => {
        await updateMediaspotStructure(
            selectedSpotAreaId,
            templates[selectedSpotAreaId]
        );
        setSelectedSpotAreaId(areaId);
    };

    const updateMediaspotStructure = async (templateId, templateStrucuture) => {
        return new Promise(async (resolve, reject) => {
            setIsUpdatingStructure(true);
            let dataToSend = {
                id: state.experienceId,
                template_id: templateId,
                structure: JSON.stringify(templateStrucuture),
            };
            try {
                const res = await updateStructure(dataToSend);
                if (res.statusCode === 200) {
                    resolve(res);
                } else {
                    toast.error(res.msg);
                }
            } catch (error) {
                toast.error(error);
                reject(error);
            } finally {
                setIsUpdatingStructure(false);
            }
        });
    };

    const experienceStructureUpdate = async () => {
        try {
            for (const spotAreasObj of spotAreas) {
                // load alredy stored data other wise get data then load
                if (Object.prototype.hasOwnProperty.call(templates, spotAreasObj.id)) {
                    let dataToSend = {
                        id: state.experienceId,
                        template_id: spotAreasObj.id,
                        structure: JSON.stringify(templates[spotAreasObj.id]),
                    };
                    await updateStructure(dataToSend);
                } else {
                    const tempStructure = await getSelectedMediaspotList(spotAreasObj.id);

                    let dataToSend = {
                        id: state.experienceId,
                        template_id: spotAreasObj.id,
                        structure: JSON.stringify(tempStructure),
                    };
                    await updateStructure(dataToSend);
                }
            }
        } catch (error) {
            console.log("annotation not found 204", error);
        }
    };

    const uploadtemplateStructure = async () => {
        setIsUploading(true);
        try {
            experienceStructureUpdate();
            if (state.mode === "editMode") {
                navigate("/experiences/create-experience/select-hotspot/add-media", {
                    state: state,
                });
            } else {
                navigate("/experiences/create-experience/select-hotspot/add-media", {
                    state: state,
                });
            }
        } catch (error) {
            console.log("uploadtemplateStructure = ", error);
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        if (state && state.experienceId) {
            fetchExperienceDetail(state.experienceId);
        }
    }, [state]);

    useEffect(() => {
        if (
            selectedSpotAreaId &&
            !Object.prototype.hasOwnProperty.call(templates, selectedSpotAreaId)
        ) {
            getSelectedMediaspotList(selectedSpotAreaId);
        }
    }, [selectedSpotAreaId]);


    const handleOpenModal = (annotationName, annotationData) => {
        console.log("annotationName", annotationData);

        setAnnotationName(annotationName);
        setAnnotationData(annotationData);

        setModalType(annotationData.widget_type.modal);
    }

    const handleCloseModal = () => {
        setModalType(null);
    }

    const handleSaveWidgetDetails = (widgetDetails) => {
        setTemplates((prevTemplates) => {
            const selectedTemplate = prevTemplates[selectedSpotAreaId]?.annotation_points[annotationName];

            if (selectedTemplate) {
                selectedTemplate.widget_details = widgetDetails;
            }

            return { ...prevTemplates };
        });
    }


    return (
        <>
            <ToastContainer position="top-right" />
            <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
                <DashboardHeader />
                <div id="viewport" className="d-flex flex-grow-1">
                    <DashboardSidebar />
                    <div className="flex-grow-1 page-content-flex">
                        <ExperienceSubHeader
                            addAnnotation
                            data={state}
                            handleClick={experienceStructureUpdate}
                        />
                        {experianceDetail && (
                            <div className="page-content-wrap">
                                <div className="heading-section page-heading d-lg-flex exp-button-wrapper border-0 pb-0 mb__20 align-items-start">
                                    <div className="flex-grow-1">
                                        <nav
                                            aria-label="breadcrumb"
                                            className="vmersive-breadcrumbs mb-2"
                                        >
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to="/experiences">Experiences</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link
                                                        to={
                                                            state.mode === "editMode"
                                                                ? "/experiences/edit-experience"
                                                                : "/experiences/create-experience"
                                                        }
                                                        state={
                                                            state.mode === "editMode"
                                                                ? experianceDetail && experianceDetail.id
                                                                : {
                                                                    experienceId: experianceDetail
                                                                        ? experianceDetail.id
                                                                        : "",
                                                                }
                                                        }
                                                    >
                                                        {experianceDetail && experianceDetail.name}
                                                    </Link>
                                                </li>
                                                <li
                                                    className="breadcrumb-item active"
                                                    aria-current="page"
                                                >
                                                    <Link
                                                        to="/experiences/create-experience/select-hotspot"
                                                        state={experianceDetail.id}
                                                    >
                                                        Add Annotations
                                                    </Link>
                                                </li>
                                            </ol>
                                        </nav>
                                        <h1>Add Annotations</h1>
                                    </div>

                                    <div className="button-wrap mt-3 mt-lg-0 d-flex">
                                        <Link
                                            className="btn btn-bordered color-cancel add-btn me-3"
                                            to={
                                                state.mode === "editMode"
                                                    ? "/experiences/edit-experience/select-hotspot"
                                                    : "/experiences/create-experience/select-hotspot"
                                            }
                                            state={{
                                                experienceId: state.experienceId,
                                                mode: state.mode,
                                            }}
                                        >
                                            <Svg name="left-arrow" /> Back {" "}
                                        </Link>
                                        <button
                                            onClick={() => {
                                                uploadtemplateStructure();
                                            }}
                                            className="btn btn-primary add-btn"
                                        >
                                            {" "}
                                            {isUpdatingStructure ? (
                                                <>
                                                    <span
                                                        className="white-spinner spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="visually-hidden">Loading...</span>
                                                </>
                                            ) : (
                                                <>
                                                    {" "}
                                                    Add media <Svg name="right-arrow" />{" "}
                                                </>
                                            )}{" "}
                                        </button>
                                    </div>
                                </div>

                                <div className="nav-tabs-wrapper vmersive-tabs-2">
                                    <ul
                                        className="nav nav-pills mb__20"
                                        id="meetings"
                                        role="tablist"
                                    >
                                        {spotAreas.length > 0 &&
                                            spotAreas.map((area, areaIndex) => (
                                                <li
                                                    className="nav-item"
                                                    role="presentation"
                                                    key={areaIndex}
                                                >
                                                    <button
                                                        className={
                                                            selectedSpotAreaId === area.id
                                                                ? "nav-link active"
                                                                : "nav-link"
                                                        }
                                                        id={`tab-${areaIndex}-tab`}
                                                        data-bs-toggle="tab"
                                                        data-bs-target={`#tab-${areaIndex}`}
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`tab-${areaIndex}`}
                                                        aria-selected="false"
                                                        onClick={() => {
                                                            changeSpotArea(area.id);
                                                        }}
                                                    >
                                                        {area.name}
                                                    </button>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                                <hr className="dark-20" />
                                <div className="page-wrapper">
                                    <div className="tab-content" id="meetingsContent">
                                        {spotAreas.length > 0 &&
                                            spotAreas.map((area, areaIndex) => (
                                                <div
                                                    className={
                                                        area.id === selectedSpotAreaId
                                                            ? "tab-pane fade show active"
                                                            : "tab-pane fade"
                                                    }
                                                    id={`tab-${areaIndex}`}
                                                    role="tabpanel"
                                                    aria-labelledby={`tab-${areaIndex}-tab`}
                                                    key={`tab-${areaIndex}`}
                                                >
                                                    <div className="templates-wrapper">
                                                        {isFeatchingMediaspotDetail ? (
                                                            <Loader />
                                                        ) : (
                                                            <div className="templates-row row">
                                                                {templates &&
                                                                    templates[selectedSpotAreaId] &&
                                                                    Object.entries(
                                                                        templates[selectedSpotAreaId]
                                                                            .annotation_points
                                                                    ).map((annotation, annotationIndex) => (
                                                                        <div
                                                                            className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                                                                            key={annotationIndex}
                                                                        >
                                                                            <div className="template-card add-media-card-bg">
                                                                                <div className="img-wrap">
                                                                                    <img
                                                                                        src={annotation[1].image_url}
                                                                                        className="img-fluid"
                                                                                        alt={annotation[1].name}
                                                                                    />
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        id="template1-1"
                                                                                        name="chooseTemplate"
                                                                                        defaultChecked={
                                                                                            annotation[1].isActive === 1
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            if (e.target.checked === false) {
                                                                                                setTemplates((templates) => {
                                                                                                    templates[
                                                                                                        selectedSpotAreaId
                                                                                                    ].annotation_points[
                                                                                                        annotation[0]
                                                                                                    ].isActive = 0;
                                                                                                    return { ...templates };
                                                                                                });
                                                                                            } else {
                                                                                                setTemplates((templates) => {
                                                                                                    templates[
                                                                                                        selectedSpotAreaId
                                                                                                    ].annotation_points[
                                                                                                        annotation[0]
                                                                                                    ].isActive = 1;
                                                                                                    return { ...templates };
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    {annotation[1]?.widget_type && <div className="action-buttons-bottom">
                                                                                        <OverlayTrigger
                                                                                            overlay={
                                                                                                <Tooltip>
                                                                                                    {"Upload Media"}
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <button
                                                                                                className="btn btn-white"
                                                                                                onClick={() => {
                                                                                                    handleOpenModal(annotation[0], annotation[1]);
                                                                                                }}
                                                                                            >
                                                                                                <>
                                                                                                    <Svg name="icon-edit" />
                                                                                                </>
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                    </div>}
                                                                                </div>
                                                                                <div className="text-wrapper">
                                                                                    {annotation[1].isEditingName ? (
                                                                                        <input
                                                                                            type="text"
                                                                                            name="name"
                                                                                            defaultValue={annotation[1].name}
                                                                                            onBlur={(e) => {
                                                                                                console.log(e);
                                                                                                setTemplates((templates) => {
                                                                                                    templates[
                                                                                                        selectedSpotAreaId
                                                                                                    ].annotation_points[
                                                                                                        annotation[0]
                                                                                                    ].isEditingName = false;
                                                                                                    return { ...templates };
                                                                                                });
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                setTemplates((templates) => {
                                                                                                    templates[
                                                                                                        selectedSpotAreaId
                                                                                                    ].annotation_points[
                                                                                                        annotation[0]
                                                                                                    ].name = e.target.value;
                                                                                                    return { ...templates };
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <h5
                                                                                            title={annotation[1].name}
                                                                                            onClick={() => {
                                                                                                setTemplates((templates) => {
                                                                                                    templates[
                                                                                                        selectedSpotAreaId
                                                                                                    ].annotation_points[
                                                                                                        annotation[0]
                                                                                                    ].isEditingName = true;
                                                                                                    return { ...templates };
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            {annotation[1].name}
                                                                                        </h5>
                                                                                    )}

                                                                                    <div className="media-status mt-1">
                                                                                        <Select
                                                                                            className="custom-select-media cstom-select"
                                                                                            placeholder="Choose information widget"
                                                                                            isClearable={false}
                                                                                            options={MediaType}
                                                                                            onChange={(item) => {
                                                                                                setTemplates((templates) => {
                                                                                                    const selectedTemplate = templates[selectedSpotAreaId].annotation_points[annotation[0]];

                                                                                                    if (selectedTemplate) {
                                                                                                        selectedTemplate.widget = true;
                                                                                                        selectedTemplate.widget_type = item;
                                                                                                    }

                                                                                                    return { ...templates };
                                                                                                })
                                                                                            }}
                                                                                            defaultValue={
                                                                                                annotation[1]?.widget_type ?? null
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Image with Text */}
            <ImageTextModal
                teleportData={annotationData}
                // closeModal={closeModal}
                // teleportName={teleportName}
                // selectedSpotAreaId={selectedSpotAreaId}
                // setTemplates={setTemplates}
                // templatesData={templates}
                onSave={handleSaveWidgetDetails}
                show={modalType}
                handleClose={handleCloseModal}
            />

            {/* Video Widget */}
            <VideoTextModal
                teleportData={annotationData}
                onSave={handleSaveWidgetDetails}
                show={modalType}
                handleClose={handleCloseModal}
            />

            {/* PDF Widget */}
            <PdfTextModal
                teleportData={annotationData}
                onSave={handleSaveWidgetDetails}
                show={modalType}
                handleClose={handleCloseModal}
            />

            {/* Slider View */}
            <SliderImageModal
                teleportData={annotationData}
                onSave={handleSaveWidgetDetails}
                show={modalType}
                handleClose={handleCloseModal}
            />

            {/* Accordion Viewer */}
            <AccordianTextModal
                teleportData={annotationData}
                onSave={handleSaveWidgetDetails}
                show={modalType}
                handleClose={handleCloseModal}
            />

            {/* Form Viewer */}
            <FormModal
                teleportData={annotationData}
                onSave={handleSaveWidgetDetails}
                show={modalType}
                handleClose={handleCloseModal}
            />


            {/* Product View */}
            <ProductModal
                teleportData={annotationData}
                onSave={handleSaveWidgetDetails}
                show={modalType}
                handleClose={handleCloseModal}
            />

        </>
    );
}
