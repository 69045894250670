// export default CreateMetaspace;
import React, { useState } from "react";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";

import { Link, useNavigate } from "react-router-dom";
import { createMetaspace } from "../../actions/metaSpace";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { Formik } from "formik";

import { MetaspaceSchema } from "../../Schema/Metaspace";
//import ExperienceSubHeader from "./ExperienceSubHeader";

import { useRef } from "react";

// import { EditText } from 'react-edit-text';
// import 'react-edit-text/dist/index.css';

export default function CreateMetaspace() {

  const [isCreatingMetaspace, setIsCreatingMetaspace] = useState(false);
  const [formValue, setFormValue] = useState({
    name: '',
    description: '',
    embedCode: '',
    thumbnail: null,
    structure: '',
    build: 'build',
    metaspace_type: "simple-metaspace",
    link: "",
    templateCode: "",
    isActive: true,
  });


  const navigate = useNavigate();


  const formikRef = useRef();


  const addmetaspace = async (data) => {
    setIsCreatingMetaspace(true);
    try {
      const res = await createMetaspace(data);
      if (res.statusCode === 200) {
        toast.success(res.msg);
        navigate("/metaspaces");
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
      setIsCreatingMetaspace(false);
    }
  };



  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            {/* <div className="meeting-header">
              <ul>
                <li>
                  <Link
                    to={"/metaspaces/create-Metaspace"}
                    state={breadcrumbStateData}
                    content-attr="Create metaspace"
                    className={"active"}
                  >
                    Create MetaSpace
                  </Link>
                </li>

              </ul>
            </div> */}
            <Formik
              innerRef={formikRef}
              initialValues={formValue}
              enableReinitialize
              validationSchema={MetaspaceSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                let dataToSend = {
                  template_name: values.name,
                  description: values.description,
                  template_code: values.templateCode,
                  thumbnail: values.thumbnail,
                  template_structure: values.structure,
                  build_name: values.build,
                  metaspace_type: values.metaspace_type,
                  embed_code: values.embedCode,
                  link: values.link,
                  is_active: values.isActive ? 1 : 0,
                  template_type: 1
                };
                addmetaspace(dataToSend);
                resetForm();
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="page-content-wrap">
                    <div className="heading-section page-heading d-md-flex exp-button-wrapper">
                      <div className="flex-grow-1">
                        <nav
                          aria-label="breadcrumb"
                          className="vmersive-breadcrumbs"
                        >
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to="/metaspaces">Metaspace</Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              Create Metaspace
                            </li>
                          </ol>
                        </nav>
                        <h1>Create Metaspace</h1>
                        <p className="pt-2">
                          Here you can create new Metaspace

                        </p>
                      </div>

                    </div>

                    <div className="exp-button-wrapper-outer">
                      <div className="page-wrapper">
                        <div className="new-experience-wrap exp-button-wrapper">
                          <div className="expt-input-wrapper d-md-flex">
                            <h6 className="mb-0 exp-name">Metaspace name</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info">
                                <div className="mb-2 position-relative">
                                  <input
                                    type="text"
                                    className="form-control title"
                                    placeholder="Enter your Metaspace name here"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                  />
                                  {errors.name &&
                                    touched.name &&
                                    errors.name && (
                                      <div className="validation-msg">
                                        {errors.name &&
                                          touched.name &&
                                          errors.name}
                                      </div>
                                    )}

                                  <br />

                                </div>
                                <div className="mb-2 position-relative">
                                  <div className="mb-0 position-relative">
                                    <textarea
                                      className="form-control"
                                      placeholder="Add description"
                                      style={{ height: 60 }}
                                      name="description"
                                      defaultValue={values.description}
                                      onChange={handleChange}
                                    />
                                    {errors.description &&
                                      touched.description &&
                                      errors.description && (
                                        <div className="validation-msg">
                                          {errors.description &&
                                            touched.description &&
                                            errors.description}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="expt-input-wrapper d-md-flex">
                            <h6 className="mb-0 exp-name">Template Code</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info">
                                <div className="mb-2 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your Template code here"
                                    name="templateCode"
                                    value={values.templateCode}
                                    onChange={handleChange}
                                  />
                                  {errors.templateCode &&
                                    touched.templateCode &&
                                    errors.templateCode && (
                                      <div className="validation-msg">
                                        {errors.templateCode &&
                                          touched.templateCode &&
                                          errors.templateCode}
                                      </div>
                                    )}

                                </div>

                              </div>
                            </div>
                          </div>
                          <div className="expt-input-wrapper d-md-flex">
                            <h6 className="mb-0 exp-name">Embed Code</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info">
                                <div className="mb-2 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your Embed code here"
                                    name="embedCode"
                                    value={values.embedCode}
                                    onChange={handleChange}
                                  />
                                  {errors.embedCode &&
                                    touched.embedCode &&
                                    errors.embedCode && (
                                      <div className="validation-msg">
                                        {errors.embedCode &&
                                          touched.embedCode &&
                                          errors.embedCode}
                                      </div>
                                    )}

                                </div>

                              </div>
                            </div>
                          </div>
                          <div className="expt-input-wrapper d-md-flex">
                            <h6 className="mb-0 exp-name">Structure</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info">
                                <div className="mb-2 position-relative">
                                  <textarea
                                    className="form-control"
                                    placeholder="Add Metaspace Structure"
                                    style={{ height: 60 }}
                                    name="structure"
                                    defaultValue={values.structure}
                                    onChange={handleChange}
                                  />
                                  {errors.structure &&
                                    touched.structure &&
                                    errors.structure && (
                                      <div className="validation-msg">
                                        {errors.structure &&
                                          touched.structure &&
                                          errors.structure}
                                      </div>
                                    )}

                                </div>

                              </div>
                            </div>
                          </div>


                          <div className="expt-input-wrapper d-md-flex">
                            <h6 className="mb-0 exp-name">Select Metaspace type</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info">
                                <h4 className="mb-2 position-relative">
                                  <select
                                    className="form-select form-control"
                                    name="metaspace_type"
                                    value={values.metaspace_type}
                                    onChange={handleChange}
                                  >
                                    {/* Options for Build */}
                                    <option value="simple-metaspace">Simple metaspace</option>
                                    <option value="aerial-view">Aerial view</option>
                                    {/* Add other options as needed */}
                                  </select>
                                </h4>

                              </div>

                            </div>
                          </div>
                          <div className="expt-input-wrapper d-md-flex">
                            <h6 className="mb-0 exp-name">Thumbnail Upload</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info">
                                <h4 className="mb-2 position-relative">
                                  <input
                                    type="file"
                                    className="form-control"
                                    placeholder="Enter your Metaspace name here"
                                    name="thumbnail"
                                    value={values.thumbnail}
                                    onChange={handleChange}
                                  />
                                  {errors.thumbnail &&
                                    touched.thumbnail &&
                                    errors.thumbnail && (
                                      <div className="validation-msg">
                                        {errors.thumbnail &&
                                          touched.thumbnail &&
                                          errors.thumbnail}
                                      </div>
                                    )}

                                </h4>

                              </div>
                            </div>
                          </div>

                          <div className="expt-input-wrapper d-md-flex">
                            <h6 className="mb-0 exp-name">Link</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info">
                                <div className="mb-2 position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your Link here"
                                    name="link"
                                    value={values.link}
                                    onChange={handleChange}
                                  />
                                </div>

                              </div>
                            </div>
                          </div>

                          <div className="expt-input-wrapper d-md-flex">
                            <h6 className="mt-0 mb-0 exp-name">Active</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info">
                                <div className="position-relative">
                                  <div className="form-check form-switch">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="isActive"
                                      id="switch1"
                                      value={values.isActive}
                                      onChange={handleChange}
                                      checked={values.isActive}
                                    />
                                    <label className="form-check-label" htmlFor="switch1"></label>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>



                          <div className="expt-input-wrapper d-md-flex">
                            <h6 className="mb-0 exp-name">Select build</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info">
                                <h4 className="mb-2 position-relative">
                                  <select
                                    className="form-select form-control"
                                    name="build"
                                    value={values.build}
                                    onChange={handleChange}
                                  >
                                    {/* Options for Build */}
                                    <option value="Build">Select from previous</option>
                                    <option value="Create Build"> Create Experience</option>
                                    {/* Add other options as needed */}
                                  </select>
                                </h4>


                              </div>

                            </div>
                          </div>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={isCreatingMetaspace}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
