import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "../../common/DashboardHeader";
import DashboardSidebar from "../../common/DashboardSidebar";
import Svg from "../../common/Svg";
import video from "../../../assets/images/small.mp4";
import imgText from "../../../assets/images/image-text.jpg";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import {
  getExperienceDetail,
  getMediaSpotList,
  getSelectedMediaSpot,
  updateStructure,
} from "../../../actions/experience";
import merge from "lodash/merge";
import ContentLoader from "react-content-loader";
import ExperienceSubHeader from "../ExperienceSubHeader";
import Select from 'react-select';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import VideoTextModal from "../widget-modal/VideoTextModal";
import ImageTextModal from "../widget-modal/ImageTextModal";
import FileUploadWidget from "./FileUploadWidget";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import PdfTextModal from "../widget-modal/PdfTextModal";
import SliderImageModal from "../widget-modal/SliderImageModal";
import ProductModal from "../widget-modal/ProductModal";
import AccordianTextModal from "../widget-modal/AccordianTextModal";
import FormModal from "../widget-modal/FormModal";

const MediaType = [
  { value: '1', label: 'PDF', name: 'PDF', ext: "pdf", type: "document", modal: "pdfModal" },
  { value: '2', label: 'Text content', name: 'Text content', ext: "html", type: "html", modal: "imageTextModal" },
  { value: '3', label: 'Product card', name: 'Product card', ext: "html", type: "html", modal: "productModal" },
  { value: '4', label: 'Email sign-up', name: 'Email sign-up', ext: "html", type: "html", modal: "formModal" },
  { value: '5', label: 'Contact form', name: 'Contact form', ext: "html", type: "html", modal: "formModal" },
  { value: '6', label: 'Text with video', name: 'Text with video', ext: "mp4", type: "video", modal: "videoTextModal" },
  { value: '7', label: 'Text with Image', name: 'Text with Image', ext: "png", type: "image", modal: "imageTextModal" },
  { value: '8', label: 'Text with background', name: 'Text with background', ext: "png", type: "image", modal: "imageTextModal" },
  { value: '9', label: 'Card with content and button', name: 'Card with content and button', ext: "html", type: "html" },
  { value: '10', label: 'Text with accordian', name: 'Text with accordian', ext: "html", type: "html", modal: "accordianTextModal" },
  { value: '11', label: 'Small contact form', name: 'Small contact form', ext: "html", type: "html", modal: "formModal" },
  { value: '12', label: 'text and image with carousel', name: 'text and image with carousel', ext: "html", type: "html", modal: "sliderImageTextModal" },
];

const Loader = (props) => (
  <ContentLoader viewBox="0 0 100% 100%" height={600} width="100%" {...props}>
    <rect x="0" y="20" rx="0" ry="0" width="380" height="190" />
    <rect x="0" y="225" rx="0" ry="0" width="380" height="30" />
    <rect x="0" y="270" rx="0" ry="0" width="380" height="50" />
    <rect x="410" y="20" rx="0" ry="0" width="380" height="190" />
    <rect x="410" y="225" rx="0" ry="0" width="380" height="30" />
    <rect x="410" y="270" rx="0" ry="0" width="380" height="50" />
    <rect x="820" y="20" rx="0" ry="0" width="380" height="190" />
    <rect x="820" y="225" rx="0" ry="0" width="380" height="30" />
    <rect x="820" y="270" rx="0" ry="0" width="380" height="50" />
    <rect x="1220" y="20" rx="0" ry="0" width="380" height="190" />
    <rect x="1220" y="225" rx="0" ry="0" width="380" height="30" />
    <rect x="1220" y="270" rx="0" ry="0" width="380" height="50" />
  </ContentLoader>
);

export default function SelectMediaspot() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const imgSlider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { state } = useLocation();
  const navigate = useNavigate();

  const [isFeatchingMediaspotDetail, setIsFeatchingMMediaspotDetail] =
    useState(true);

  const [isFetchingDetail, setIsFetchingDetail] = useState(false);
  const [isUploading, setIsUploading] = useState(true);
  const [isUpdatingStructure, setIsUpdatingStructure] = useState(false);

  const [experianceDetail, setExperianceDetail] = useState(null);
  const [spotAreas, setSpotAreas] = useState([]);
  const [selectedSpotAreaId, setSelectedSpotAreaId] = useState(null);

  const [templates, setTemplates] = useState({});

  const [isEditing, setIsEditing] = useState({});

  // const uploadModal = useRef(null);
  // const [isOpenModal, setIsOpenModal] = useState(false);
  const [teleportName, setTeleportName] = useState("");
  const [teleportData, setTeleportData] = useState({});
  const [modalType, setModalType] = useState(null);
  const [parentIds, setParentIds] = useState([{ value: "", label: "None" }]);


  const fetchExperienceDetail = async (experienceId) => {
    setIsFetchingDetail(true);
    let dataToSend = {
      id: experienceId,
    };
    try {
      const res = await getExperienceDetail(dataToSend);
      if (res.statusCode === 200) {
        setExperianceDetail(res.data);
        setSpotAreas(res.data.templates);
        setSelectedSpotAreaId(res.data.templates[0].id);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsFetchingDetail(false);
    }
  };

  const updateTemplates = (templateId, templateData) => {
    setTemplates((prevState) => {
      return {
        ...prevState,
        [templateId]: templateData,
      };
    });

    const teleportKeys = Object.keys(templateData["teleport_points"]).map((key) => ({value: key, label: key }) );
    teleportKeys.push({ value: "", label: "None" });
    setParentIds(teleportKeys);

  };

  const mergeTemplateData = (masterData, experienceData, templateId) => {
    if (typeof experienceData === "string") {
      experienceData = JSON.parse(experienceData);
    }
    // if has no data then clone master data else merge data
    if (
      !Object.prototype.hasOwnProperty.call(experienceData, "teleport_count")
    ) {
      return masterData;
    } else {
      // load alredy stored data other wise get data then load - because data might be modified by user
      if (Object.prototype.hasOwnProperty.call(templates, templateId)) {
        return templates[templateId];
      }
      // merge data here
      let mergedData = merge(masterData, experienceData);
      mergedData = {
        ...mergedData,
        media_count: Object.keys(mergedData.media_spots).length,
        teleport_count: Object.keys(mergedData.teleport_points).length,
      };
      return mergedData;
    }
  };

  const getSelectedMediaspotList = async (templateId) => {
    return new Promise(async (resolve, reject) => {
      setIsFeatchingMMediaspotDetail(true);
      let dataToSend = {
        t_id: templateId,
      };
      try {
        const masterHotspot = await getMediaSpotList(dataToSend);
        if (masterHotspot.statusCode === 200) {
          let dataToSend = {
            id: state.experienceId,
            t_id: templateId,
          };
          const experienceHotspot = await getSelectedMediaSpot(dataToSend);
          if (experienceHotspot.statusCode === 200) {
            let newTemplateData = mergeTemplateData(
              masterHotspot.data[0].template_structure,
              experienceHotspot.data.structure,
              templateId
            );
            updateTemplates(templateId, newTemplateData);
            resolve(newTemplateData);
          } else {
            toast.error(experienceHotspot.msg);
            reject("no data");
          }
        } else {
          toast.error(masterHotspot.msg);
          reject("no data");
        }
      } catch (error) {
        toast.error(error);
        reject("no data");
      } finally {
        setIsFeatchingMMediaspotDetail(false);
      }
    });
  };

  const changeSpotArea = async (areaId) => {
    await updateMediaspotStructure(
      selectedSpotAreaId,
      templates[selectedSpotAreaId]
    );
    setSelectedSpotAreaId(areaId);
  };

  const updateMediaspotStructure = async (templateId, templateStrucuture) => {
    return new Promise(async (resolve, reject) => {
      setIsUpdatingStructure(true);
      let dataToSend = {
        id: state.experienceId,
        template_id: templateId,
        structure: JSON.stringify(templateStrucuture),
      };
      try {
        const res = await updateStructure(dataToSend);
        if (res.statusCode === 200) {
          resolve(res);
        } else {
          toast.error(res.msg);
        }
      } catch (error) {
        toast.error(error);
        reject(error);
      } finally {
        setIsUpdatingStructure(false);
      }
    });
  };

  const experienceStructureUpdate = async () => {
    try {
      for (const spotAreasObj of spotAreas) {
        // load alredy stored data other wise get data then load
        if (Object.prototype.hasOwnProperty.call(templates, spotAreasObj.id)) {
          let dataToSend = {
            id: state.experienceId,
            template_id: spotAreasObj.id,
            structure: JSON.stringify(templates[spotAreasObj.id]),
          };
          await updateStructure(dataToSend);
        } else {
          const tempStructure = await getSelectedMediaspotList(spotAreasObj.id);

          let dataToSend = {
            id: state.experienceId,
            template_id: spotAreasObj.id,
            structure: JSON.stringify(tempStructure),
          };
          await updateStructure(dataToSend);
        }
      }
    } catch (error) {
      console.log("select media spot 204", error);
    }
  };

  const uploadtemplateStructure = async () => {
    setIsUploading(true);
    try {
      experienceStructureUpdate();
      if (state.mode === "editMode") {
        navigate("/experiences/create-experience/select-hotspot/add-annotations", {
          state: state,
        });
      } else {
        navigate("/experiences/create-experience/select-hotspot/add-annotations", {
          state: state,
        });
      }
    } catch (error) {
      console.log("uploadtemplateStructure = ", error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (state && state.experienceId) {
      fetchExperienceDetail(state.experienceId);
    }
  }, [state]);

  useEffect(() => {
    if (
      selectedSpotAreaId &&
      !Object.prototype.hasOwnProperty.call(templates, selectedSpotAreaId)
    ) {
      getSelectedMediaspotList(selectedSpotAreaId);
    }
  }, [selectedSpotAreaId]);


  const openModal = (selectedSpotAreaId, teleportName, teleportData) => {

    setTeleportName(teleportName);
    setTeleportData(teleportData);

    const modalId = teleportData.widget_type.modal;

    const mediaModal = window.bootstrap.Modal.getOrCreateInstance(
      document.getElementById(modalId)
    );
    mediaModal.show();


    // setIsOpenModal(true);

  };

  const closeModal = () => {
    const modalId = teleportData.widget_type.modal;
    window.bootstrap.Modal.getOrCreateInstance(document.getElementById(modalId)).hide();
  };

  const handleOpenModal = (teleportName, teleportData) => {
    console.log("teleportName", teleportData);

    setTeleportName(teleportName);
    setTeleportData(teleportData);

    setModalType(teleportData.widget_type.modal);
  }

  const handleCloseModal = () => {
    setModalType(null);
  }

  const handleSaveWidgetDetails = (widgetDetails) => {
    setTemplates((prevTemplates) => {
      const selectedTemplate = prevTemplates[selectedSpotAreaId]?.teleport_points[teleportName];

      if (selectedTemplate) {
        selectedTemplate.widget_details = widgetDetails;
      }

      return { ...prevTemplates };
    });
  }


  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <ExperienceSubHeader
              selectMediaSpot
              redirect={false}
              data={state}
              handleClick={experienceStructureUpdate}
            />
            {experianceDetail && (
              <div className="page-content-wrap">
                <div className="heading-section page-heading d-lg-flex exp-button-wrapper border-0 pb-0 mb__20 align-items-start">
                  <div className="flex-grow-1">
                    {/* <h6 className="pb-2">
                      <strong>Experience: </strong>
                      {!isFetchingDetail && experianceDetail.name}
                    </h6> */}
                    <nav
                      aria-label="breadcrumb"
                      className="vmersive-breadcrumbs mb-2"
                    >
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/experiences">Experiences</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link
                            to={
                              state.mode === "editMode"
                                ? "/experiences/edit-experience"
                                : "/experiences/create-experience"
                            }
                            state={
                              state.mode === "editMode"
                                ? experianceDetail && experianceDetail.id
                                : {
                                  experienceId: experianceDetail
                                    ? experianceDetail.id
                                    : "",
                                }
                            }
                          >
                            {experianceDetail && experianceDetail.name}
                          </Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          <Link
                            to="/experiences/create-experience/select-hotspot"
                            state={experianceDetail.id}
                          >
                            Select Hotspots
                          </Link>
                        </li>
                      </ol>
                    </nav>
                    <h1>Select Hotspots </h1>
                  </div>

                  <div className="button-wrap mt-3 mt-lg-0 d-flex">
                    <Link
                      to={
                        state.mode === "editMode"
                          ? "/experiences/edit-experience"
                          : "/experiences/create-experience"
                      }
                      state={
                        state.mode === "editMode"
                          ? experianceDetail.id
                          : { experienceId: experianceDetail.id }
                      }
                      className="btn btn-bordered color-cancel add-btn me-3"
                    >
                      {" "}
                      <Svg name="left-arrow" /> Back{" "}
                    </Link>
                    <button
                      onClick={() => {
                        uploadtemplateStructure();
                      }}
                      className="btn btn-primary add-btn"
                    >
                      {" "}
                      {isUpdatingStructure ? (
                        <>
                          <span
                            className="white-spinner spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        <>
                          {" "}
                          Add Annotations <Svg name="right-arrow" />{" "}
                        </>
                      )}{" "}
                    </button>
                  </div>
                </div>

                <div className="nav-tabs-wrapper vmersive-tabs-2">
                  <ul
                    className="nav nav-pills mb__20"
                    id="meetings"
                    role="tablist"
                  >
                    {spotAreas.length > 0 &&
                      spotAreas.map((area, areaIndex) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={areaIndex}
                        >
                          <button
                            className={
                              selectedSpotAreaId === area.id
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id={`tab-${areaIndex}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#tab-${areaIndex}`}
                            type="button"
                            role="tab"
                            aria-controls={`tab-${areaIndex}`}
                            aria-selected="false"
                            onClick={() => {
                              changeSpotArea(area.id);
                            }}
                          >
                            {area.name}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
                <hr className="dark-20" />
                <div className="page-wrapper">
                  <div className="tab-content" id="meetingsContent">
                    {spotAreas.length > 0 &&
                      spotAreas.map((area, areaIndex) => (
                        <div
                          className={
                            area.id === selectedSpotAreaId
                              ? "tab-pane fade show active"
                              : "tab-pane fade"
                          }
                          id={`tab-${areaIndex}`}
                          role="tabpanel"
                          aria-labelledby={`tab-${areaIndex}-tab`}
                          key={`tab-${areaIndex}`}
                        >
                          <div className="templates-wrapper">
                            {/* <div className="select-hotspot-text">
                                  <p>{area.name} - Select hotspot areas</p>
                                </div> */}
                            {isFeatchingMediaspotDetail ? (
                              <Loader />
                            ) : (
                              <div className="templates-row row">
                                {templates &&
                                  templates[selectedSpotAreaId] &&
                                  Object.entries(
                                    templates[selectedSpotAreaId]
                                      .teleport_points
                                  ).map((teleport, teleportIndex) => (
                                    <div
                                      className="col-xxl-3 col-xl-4 col-md-6 mb-3"
                                      key={teleportIndex}
                                    >
                                      <div className="template-card add-media-card-bg">
                                        <div className="img-wrap">
                                          <img
                                            src={teleport[1].image_url}
                                            className="img-fluid"
                                            alt={teleport[1].name}
                                          />
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="template1-1"
                                            name="chooseTemplate"
                                            defaultChecked={
                                              teleport[1].isActive === 1
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => {
                                              if (e.target.checked === false) {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].isActive = 0;
                                                  return { ...templates };
                                                });
                                              } else {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].isActive = 1;
                                                  return { ...templates };
                                                });
                                              }
                                            }}
                                          />
                                          {teleport[1]?.widget_type && <div className="action-buttons-bottom">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>
                                                  {"Upload Media"}
                                                </Tooltip>
                                              }
                                            >
                                              <button
                                                className="btn btn-white"
                                                onClick={() => {
                                                  // openModal(selectedSpotAreaId, teleport[0], teleport[1]);
                                                  handleOpenModal(teleport[0], teleport[1]);
                                                }}
                                              >
                                                <>
                                                  <Svg name="icon-edit" />
                                                </>
                                              </button>
                                            </OverlayTrigger>
                                          </div>}
                                        </div>
                                        <div className="text-wrapper">
                                          {teleport[1].isEditingName ? (
                                            <input
                                              type="text"
                                              name="name"
                                              defaultValue={teleport[1].name}
                                              onBlur={(e) => {
                                                console.log(e);
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].isEditingName = false;
                                                  return { ...templates };
                                                });
                                              }}
                                              onChange={(e) => {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].name = e.target.value;
                                                  return { ...templates };
                                                });
                                              }}
                                            />
                                          ) : (
                                            <h5
                                              title={teleport[1].name}
                                              onClick={() => {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].isEditingName = true;
                                                  return { ...templates };
                                                });
                                              }}
                                            >
                                              {teleport[1].name}
                                            </h5>
                                          )}

                                          <div className="media-status mt-1">
                                            <Select
                                              className="custom-select-media cstom-select"
                                              placeholder="Choose Parent"
                                              isClearable={false}
                                              options={parentIds}
                                              onChange={(item) => {
                                                setTemplates((templates) => {
                                                  const selectedTemplate = templates[selectedSpotAreaId].teleport_points[teleport[0]];

                                                  if (selectedTemplate) {
                                                    selectedTemplate.parent_id = item.value;
                                                  }

                                                  return { ...templates };
                                                })
                                              }}
                                              defaultValue={
                                                teleport[1]?.parent_id ? {
                                                  value: teleport[1]?.parent_id,
                                                  label: teleport[1]?.parent_id === "" ? "None" : teleport[1]?.parent_id
                                                } : null
                                              }
                                            />
                                          </div>

                                          <div className="media-status mt-1">
                                            <Select
                                              className="custom-select-media cstom-select"
                                              placeholder="Choose information widget"
                                              isClearable={false}
                                              options={MediaType}
                                              onChange={(item) => {
                                                setTemplates((templates) => {
                                                  const selectedTemplate = templates[selectedSpotAreaId].teleport_points[teleport[0]];

                                                  if (selectedTemplate) {
                                                    selectedTemplate.widget = true;
                                                    selectedTemplate.widget_type = item;
                                                  }

                                                  return { ...templates };
                                                })
                                              }}
                                              defaultValue={
                                                teleport[1]?.widget_type ?? null
                                              }
                                            />
                                          </div>

                                          {/* <div className="description d-none">
                                            <textarea
                                              className={
                                                teleport[1].description.length >
                                                  200
                                                  ? "form-control error"
                                                  : "form-control"
                                              }
                                              name="description"
                                              defaultValue={
                                                teleport[1].description
                                              }
                                              placeholder="Add hotspot description"
                                              onChange={(e) => {
                                                setTemplates((templates) => {
                                                  templates[
                                                    selectedSpotAreaId
                                                  ].teleport_points[
                                                    teleport[0]
                                                  ].description =
                                                    e.target.value;
                                                  return { ...templates };
                                                });
                                              }}
                                            />
                                            <div className="form-text text-end word-count">
                                              {teleport[1].description.length}
                                              /200
                                            </div>
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Image with Text */}
      <ImageTextModal
        teleportData={teleportData}
        // closeModal={closeModal}
        // teleportName={teleportName}
        // selectedSpotAreaId={selectedSpotAreaId}
        // setTemplates={setTemplates}
        // templatesData={templates}
        onSave={handleSaveWidgetDetails}
        show={modalType}
        handleClose={handleCloseModal}
      />

      {/* Video Widget */}
      <VideoTextModal
        teleportData={teleportData}
        onSave={handleSaveWidgetDetails}
        show={modalType}
        handleClose={handleCloseModal}
      />

      {/* PDF Widget */}
      <PdfTextModal
        teleportData={teleportData}
        onSave={handleSaveWidgetDetails}
        show={modalType}
        handleClose={handleCloseModal}
      />

      {/* Slider View */}
      <SliderImageModal
        teleportData={teleportData}
        onSave={handleSaveWidgetDetails}
        show={modalType}
        handleClose={handleCloseModal}
      />

      {/* Accordion Viewer */}
      <AccordianTextModal
        teleportData={teleportData}
        onSave={handleSaveWidgetDetails}
        show={modalType}
        handleClose={handleCloseModal}
      />

      {/* Form Viewer */}
      <FormModal
        teleportData={teleportData}
        onSave={handleSaveWidgetDetails}
        show={modalType}
        handleClose={handleCloseModal}
      />


      {/* Product View */}
      <ProductModal
        teleportData={teleportData}
        onSave={handleSaveWidgetDetails}
        show={modalType}
        handleClose={handleCloseModal}
      />


      <div>

        <Button variant="primary" onClick={handleShow}>
          Launch react modal
        </Button>

        <Modal
          show={show}
          onHide={handleClose}
          className="customvar-modal modal fade fileUpload uploadMedia"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-details-width"
        >
          <Modal.Body>

            {/* Image with Text */}
            <div className="widget-code d-none">
              <div className="heading">
                <h4>Edit Widget</h4>
              </div>
              <div className="d-flex text-image-widget align-items-start">
                <div className="img-text-wrap">
                  <div className="img-rap">
                    <img src={imgText} alt="" className="img-fluid" />
                  </div>
                  <div className="text-wrap">
                    <h4>Nulla ut iaculis augue, at lacinia velit. Maecenas a risus</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                    <p>Sed ex mi, imperdiet ultricies consectetur tempor</p>
                    <ul>
                      <li>Lorem ipsum dolor sit amet consectetur</li>
                      <li>Aadipisicing elit.</li>
                      <li>Provident aut vero ipsum temporibus</li>
                    </ul>
                  </div>
                </div>
                <div className="flex-grow-1 widget-fields">
                  <FileUploadWidget />
                  <div className="form-group mb-3">
                    <div className="label">Insert text here</div>
                    <div className="editor-wrapper">
                      ---Editor Here---
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <div className="label button-label" data-bs-toggle="collapse" data-bs-target="#collapsePrimary" aria-expanded="false" aria-controls="collapsePrimary">Primary button</div>
                        <div className="collapse" id="collapsePrimary">
                          <div className="card card-body buttons-body">
                            <div className="field-wrapper">
                              <div className="row mb-3">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">Text on button</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>
                              <div className="row">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">URL</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <div className="label button-label" data-bs-toggle="collapse" data-bs-target="#collapseSecondary" aria-expanded="false" aria-controls="collapseSecondary">Secondary button</div>
                        <div className="collapse" id="collapseSecondary">
                          <div className="card card-body buttons-body">
                            <div className="field-wrapper">
                              <div className="row mb-3">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">Text on button</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>
                              <div className="row">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">URL</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                    <button type="button" className="btn btn-primary" >Save widget</button>
                    <button type="button" className="btn btn-bordered color-cancel ms-3" onClick={closeModal}>Cancel</button>
                  </div>

                </div>
              </div>

            </div>

            {/* Video Widget */}

            <div className="widget-code d-none">
              <div className="heading">
                <h4>Edit Widget</h4>
              </div>
              <div className="d-flex text-image-widget text-video-widget align-items-start">
                <div className="img-text-wrap">
                  <div className="video-wrap">
                    <video width={320} height={240} controls={false}>
                      <source src={video} type="video/mp4" />
                      <source src={video} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                    <div className="cross-button">
                      <Svg name="cross-button" />
                    </div>
                    <div className="play-button">
                      <button className="play-btn"><Svg name="play-button" />  </button>
                    </div>
                  </div>
                  <div className="text-outer">
                    <div className="text-wrap">
                      <h4>Nulla ut iaculis augue, at lacinia velit. Maecenas a risus</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                      <p>Sed ex mi, imperdiet ultricies consectetur tempor</p>
                      <ul>
                        <li>Lorem ipsum dolor sit amet consectetur</li>
                        <li>Aadipisicing elit.</li>
                        <li>Provident aut vero ipsum temporibus</li>
                      </ul>
                    </div>
                    <div className="button-wrap">
                      <button className="btn btn-primary">Button</button>
                      <button className="btn btn-bordered">Button</button>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 widget-fields">
                  <FileUploadWidget />
                  <div className="form-group mb-3">
                    <div className="label">Insert text here</div>
                    <div className="editor-wrapper">
                      ---Editor Here---
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <div className="label button-label" data-bs-toggle="collapse" data-bs-target="#collapsePrimary" aria-expanded="false" aria-controls="collapsePrimary">Primary button</div>
                        <div className="collapse" id="collapsePrimary">
                          <div className="card card-body buttons-body">
                            <div className="field-wrapper">
                              <div className="row mb-3">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">Text on button</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>
                              <div className="row">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">URL</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <div className="label button-label" data-bs-toggle="collapse" data-bs-target="#collapseSecondary" aria-expanded="false" aria-controls="collapseSecondary">Secondary button</div>
                        <div className="collapse" id="collapseSecondary">
                          <div className="card card-body buttons-body">
                            <div className="field-wrapper">
                              <div className="row mb-3">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">Text on button</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>
                              <div className="row">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">URL</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                    <button type="button" className="btn btn-primary">Save widget</button>
                    <button type="button" className="btn btn-bordered color-cancel ms-3">Cancel</button>
                  </div>

                </div>
              </div>
            </div>

            {/* Slider View */}

            <div className="widget-code d-none">
              <div className="heading">
                <h4>Edit Widget</h4>
              </div>
              <div className="d-flex text-image-widget text-video-widget align-items-start">
                <div className="img-text-wrap">
                  <div className="slider-wrapper">
                    <Slider {...settings}>
                      <div>
                        <div className="slider-inner">
                          <div className="video-wrap">
                            <div className="img-wrap">
                              <img src={imgText} alt="" className="img-fluid" />
                            </div>
                            <div className="cross-button">
                              <Svg name="cross-button" />
                            </div>

                          </div>
                          <div className="text-outer">
                            <div className="text-wrap">
                              <h4>Nulla ut iaculis augue, at lacinia velit. Maecenas a risus</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                              <p>Sed ex mi, imperdiet ultricies consectetur tempor</p>
                              <ul>
                                <li>Lorem ipsum dolor sit amet consectetur</li>
                                <li>Aadipisicing elit.</li>
                                <li>Provident aut vero ipsum temporibus</li>
                              </ul>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="slider-inner">
                          <div className="video-wrap">
                            <div className="img-wrap">
                              <img src={imgText} alt="" className="img-fluid" />
                            </div>
                            <div className="cross-button">
                              <Svg name="cross-button" />
                            </div>

                          </div>
                          <div className="text-outer">
                            <div className="text-wrap">
                              <h4>Nulla ut iaculis augue, at lacinia velit. Maecenas a risus</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                              <p>Sed ex mi, imperdiet ultricies consectetur tempor</p>
                              <ul>
                                <li>Lorem ipsum dolor sit amet consectetur</li>
                                <li>Aadipisicing elit.</li>
                                <li>Provident aut vero ipsum temporibus</li>
                              </ul>
                            </div>

                          </div>
                        </div>
                      </div>

                      {/* Add more slides as needed */}
                    </Slider>
                  </div>
                </div>
                <div className="flex-grow-1 widget-fields slide-view">
                  <div className="slide-wrapper">
                    <div className="slide-list">
                      <div className="slide-title">
                        Slide 1
                      </div>
                      <div className="slide-inner">
                        <FileUploadWidget />
                        <div className="form-group mb-3">
                          <div className="label">Insert text here</div>
                          <div className="editor-wrapper">
                            ---Editor Here---
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slide-list">
                      <div className="slide-title">
                        Slide 2
                      </div>
                      <div className="slide-inner">
                        <FileUploadWidget />
                        <div className="form-group mb-3">
                          <div className="label">Insert text here</div>
                          <div className="editor-wrapper">
                            ---Editor Here---
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="add-button mb-3">
                    <button className="add-slide-button">Add Slide</button>
                  </div>

                  <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                    <button type="button" className="btn btn-primary">Save widget</button>
                    <button type="button" className="btn btn-bordered color-cancel ms-3">Cancel</button>
                  </div>

                </div>
              </div>
            </div>

            {/* Accordion Viewer */}

            <div className="widget-code d-none">
              <div className="heading">
                <h4>Edit Widget</h4>
              </div>
              <div className="d-flex text-image-widget text-video-widget align-items-start">
                <div className="img-text-wrap">
                  <div className="accordion-wrapper">
                    <div className="heading">
                      <h4>Accordion Heading</h4>
                    </div>
                    <div className="accordion" id="accordionWidget">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="accordionheading1">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordionCollapse1" aria-expanded="true" aria-controls="accordionCollapse1">
                            Accordion Item #1
                          </button>
                        </h2>
                        <div id="accordionCollapse1" className="accordion-collapse collapse show" aria-labelledby="accordionheading1" data-bs-parent="#accordionWidget">
                          <div className="accordion-body">
                            <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="accordionheading2">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionCollapse2" aria-expanded="false" aria-controls="accordionCollapse2">
                            Accordion Item #2
                          </button>
                        </h2>
                        <div id="accordionCollapse2" className="accordion-collapse collapse" aria-labelledby="accordionheading2" data-bs-parent="#accordionWidget">
                          <div className="accordion-body">
                            <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="accordionheading3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionCollapse3" aria-expanded="false" aria-controls="accordionCollapse3">
                            Accordion Item #3
                          </button>
                        </h2>
                        <div id="accordionCollapse3" className="accordion-collapse collapse" aria-labelledby="accordionheading3" data-bs-parent="#accordionWidget">
                          <div className="accordion-body">
                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
                <div className="flex-grow-1 widget-fields accordion-view">
                  <FileUploadWidget />
                  <div className="form-group mb-3">
                    <div className="label">Heading</div>
                    <div className="editor-wrapper">
                      ---Editor Here---
                    </div>
                  </div>
                  <div className="slide-wrapper">
                    <div className="slide-list">
                      <div className="slide-title">
                        Accordion 1
                      </div>
                      <div className="slide-inner">
                        <div className="form-group mb-3">
                          <div className="label">Heading</div>
                          <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                        </div>
                        <div className="form-group mb-3">
                          <div className="label">Paragraph</div>
                          <div className="editor-wrapper">
                            ---Editor Here---
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slide-list">
                      <div className="slide-title">
                        Accordion 2
                      </div>
                      <div className="slide-inner">
                        <div className="form-group mb-3">
                          <div className="label">Heading</div>
                          <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                        </div>
                        <div className="form-group mb-3">
                          <div className="label">Paragraph</div>
                          <div className="editor-wrapper">
                            ---Editor Here---
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="add-button mb-3">
                    <button className="add-slide-button">Add Accordion</button>
                  </div>

                  <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                    <button type="button" className="btn btn-primary">Save widget</button>
                    <button type="button" className="btn btn-bordered color-cancel ms-3">Cancel</button>
                  </div>

                </div>
              </div>
            </div>

            {/* Form Viewer */}

            <div className="widget-code d-none">
              <div className="heading">
                <h4>Edit Widget</h4>
              </div>
              <div className="d-flex text-image-widget text-video-widget align-items-start">
                <div className="img-text-wrap">
                  <div className="form-widget-wrapper">
                    <div className="text">
                      <h3>Contact Us</h3>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                    </div>
                    <div className="form-inner">
                      <div className="form-group mb-3">
                        <label htmlFor="field1" className="form-label">Name</label>
                        <input type="text" className="form-control" id="field1" />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="field2" className="form-label">Email</label>
                        <input type="email" className="form-control" id="field2" />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="field3" className="form-label">Message</label>
                        <textarea className="form-control" id="field3" rows={3} defaultValue={""} />
                      </div>
                      <div className="mb-3 form-check text-center">
                        <input type="checkbox" className="form-check-input" id="terms" />
                        <label className="form-check-label" htmlFor="terms">I accept the <a href="#" target="_blank" rel="noopener noreferrer">Terms</a></label>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-primary">Submit</button>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="flex-grow-1 widget-fields form-view">

                  <div className="form-group mb-3">
                    <div className="label">Heading</div>
                    <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                  </div>
                  <div class="form-group mb-3">
                    <label for="field3" class="form-label">Description</label>
                    <textarea class="form-control" id="field3" rows="3" placeholder="Enter your description here"></textarea>
                  </div>
                  <div className="slide-wrapper">
                    <div className="slide-list mb-3">
                      <div className="slide-title">
                        Text filed
                      </div>
                      <div className="field-wrapper d-flex align-items-center">
                        <div className="field-inner flex-grow-1 pe-3">
                          <div className="slide-inner mb-0">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group mb-3">
                                  <div className="d-flex justify-content-between">
                                    <div className="label">Label </div>
                                    <div className="form-check form-switch">
                                      <input className="form-check-input" type="checkbox" id="switch1" />
                                      <label className="form-check-label" htmlFor="switch1"></label>
                                    </div>


                                  </div>
                                  <input type="text" name="" className="form-control" placeholder="Full name" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group mb-3">
                                  <div className="label">Placeholder</div>
                                  <input type="text" name="" className="form-control" placeholder="Enter your full name" />
                                </div>
                              </div>

                            </div>
                            <div className="more-setting">
                              <button className="btn more-setting-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#setting1" aria-expanded="false" aria-controls="setting1">
                                More settings</button>
                              <div className="collapse" id="setting1">
                                <div className="setting-inner">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="label">Validation </div>
                                          <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="switch11" />
                                            <label className="form-check-label" htmlFor="switch11"></label>
                                          </div>
                                        </div>
                                        <select className="form-select">
                                          <option value={1}>Contains Validations</option>
                                          <option value={2}>Doesn’t contains</option>
                                        </select>

                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group mb-3">
                                        <div className="label">Enter characters/formula</div>
                                        <input type="text" name="" className="form-control" placeholder="Enter text" />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <div className="label">Error message</div>
                                        <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <div className="d-flex">
                                          <div className="label me-2">Is required </div>
                                          <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="switch12" />
                                            <label className="form-check-label" htmlFor="switch12"></label>
                                          </div>
                                        </div>
                                        <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                      </div>
                                    </div>

                                  </div>
                                </div>

                              </div>


                            </div>

                          </div>
                        </div>
                        <div className="remove-button"><Svg name="trash-icon" /></div>
                      </div>
                    </div>

                    <div className="slide-list mb-3">
                      <div className="slide-title">
                        Email
                      </div>
                      <div className="field-wrapper d-flex align-items-center">
                        <div className="field-inner flex-grow-1 pe-3">
                          <div className="slide-inner mb-0">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group mb-3">
                                  <div className="d-flex justify-content-between">
                                    <div className="label">Label </div>
                                    <div className="form-check form-switch">
                                      <input className="form-check-input" type="checkbox" id="switch2" />
                                      <label className="form-check-label" htmlFor="switch2"></label>
                                    </div>
                                  </div>
                                  <input type="text" name="" className="form-control" placeholder="Email" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group mb-3">
                                  <div className="label">Placeholder</div>
                                  <input type="text" name="" className="form-control" placeholder="Enter your email" />
                                </div>
                              </div>

                            </div>
                            <div className="more-setting">
                              <button className="btn more-setting-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#setting2" aria-expanded="false" aria-controls="setting2">
                                More settings</button>
                              <div className="collapse" id="setting2">
                                <div className="setting-inner">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="label">Validation </div>
                                          <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="switch21" />
                                            <label className="form-check-label" htmlFor="switch21"></label>
                                          </div>
                                        </div>
                                        <select className="form-select">
                                          <option value={1}>Contains Validations</option>
                                          <option value={2}>Doesn’t contains</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group mb-3">
                                        <div className="label">Enter characters/formula</div>
                                        <input type="text" name="" className="form-control" placeholder="Enter text" />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <div className="label">Error message</div>
                                        <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <div className="d-flex">
                                          <div className="label me-2">Is required </div>
                                          <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="switch22" />
                                            <label className="form-check-label" htmlFor="switch22"></label>
                                          </div>
                                        </div>
                                        <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                      </div>
                                    </div>

                                  </div>
                                </div>

                              </div>


                            </div>

                          </div>
                        </div>
                        <div className="remove-button"><Svg name="trash-icon" /></div>
                      </div>
                    </div>

                    <div className="slide-list mb-3">
                      <div className="slide-title">
                        Dropdown
                      </div>
                      <div className="field-wrapper d-flex align-items-center">
                        <div className="field-inner flex-grow-1 pe-3">
                          <div className="slide-inner mb-0">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group mb-3">
                                  <div className="d-flex justify-content-between">
                                    <div className="label">Label </div>
                                    <div className="form-check form-switch">
                                      <input className="form-check-input" type="checkbox" id="switch3" />
                                      <label className="form-check-label" htmlFor="switch3"></label>
                                    </div>
                                  </div>
                                  <input type="text" name="" className="form-control" placeholder="Email" />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group mb-3">
                                  <div className="label">Options</div>
                                  <div class="mb-2 d-flex align-items-center">
                                    <div class="flex-grow-1 pe-3">
                                      <input type="text" name="" className="form-control" placeholder="Option 1" />
                                    </div>
                                    <div class="action-button">
                                      <button class="action-btn remove">
                                        <Svg name="option-remove-icon" />
                                      </button>
                                    </div>
                                  </div>
                                  <div class="mb-2 d-flex align-items-center">
                                    <div class="flex-grow-1 pe-3">
                                      <input type="text" name="" className="form-control" placeholder="Option 2" />
                                    </div>
                                    <div class="action-button">
                                      <button class="action-btn remove">
                                        <Svg name="option-remove-icon" />
                                      </button>
                                    </div>
                                  </div>

                                  <div class="mb-2 d-flex align-items-center">
                                    <div class="flex-grow-1 pe-3">
                                      <input type="text" name="" className="form-control" placeholder="Option 3" />
                                    </div>
                                    <div class="action-button">
                                      <button class="action-btn add">
                                        <Svg name="option-add-icon" />
                                      </button>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                            <div className="more-setting">
                              <button className="btn more-setting-btn collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#setting3" aria-expanded="false" aria-controls="setting3">
                                More settings</button>
                              <div className="collapse" id="setting3">
                                <div className="setting-inner">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group mb-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="label">Validation </div>
                                          <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="switch31" />
                                            <label className="form-check-label" htmlFor="switch31"></label>
                                          </div>
                                        </div>
                                        <select className="form-select">
                                          <option value={1}>Contains Validations</option>
                                          <option value={2}>Doesn’t contains</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group mb-3">
                                        <div className="label">Enter characters/formula</div>
                                        <input type="text" name="" className="form-control" placeholder="Enter text" />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <div className="label">Error message</div>
                                        <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group mb-3">
                                        <div className="d-flex">
                                          <div className="label me-2">Is required </div>
                                          <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="switch32" />
                                            <label className="form-check-label" htmlFor="switch32"></label>
                                          </div>
                                        </div>
                                        <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                      </div>
                                    </div>

                                  </div>
                                </div>

                              </div>


                            </div>

                          </div>
                        </div>
                        <div className="remove-button"><Svg name="trash-icon" /></div>
                      </div>
                    </div>


                    <div className="slide-list mb-3">
                      <div className="dropdown add-field">
                        <button className="btn add-field-button" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          <Svg name="plus" /> Add field
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a className="dropdown-item" href="#">Text</a></li>
                          <li><a className="dropdown-item" href="#">Email</a></li>
                          <li><a className="dropdown-item" href="#">Text area </a></li>
                          <li><a className="dropdown-item" href="#">Dropdown </a></li>
                          <li><a className="dropdown-item" href="#">Number </a></li>
                          <li><a className="dropdown-item" href="#">Checkbox </a></li>
                          <li><a className="dropdown-item" href="#">Radio button </a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                    <button type="button" className="btn btn-primary">Save widget</button>
                    <button type="button" className="btn btn-bordered color-cancel ms-3">Cancel</button>
                  </div>

                </div>
              </div>
            </div>


            {/* PDF Viewer */}

            <div className="widget-code d-none">
              <div className="heading">
                <h4>Edit Widget</h4>
              </div>
              <div className="d-flex text-image-widget pdf viewer align-items-start">
                <div className="img-text-wrap">
                  Please add PDF Preview Here
                </div>
                <div className="flex-grow-1 widget-fields">
                  <div className="file-upload form-group mb-3">
                    <div className="label">Upload an image</div>
                    <input className="form-control" type="file" id="formFile" />
                    <div className="form-text">Dimensions: 400 x 600px | Dimensions: 400 x 600px Supported format: PNG,JPEG,JPG</div>
                  </div>

                  <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                    <button type="button" className="btn btn-primary" >Save widget</button>
                    <button type="button" className="btn btn-bordered color-cancel ms-3" onClick={closeModal}>Cancel</button>
                  </div>

                </div>
              </div>

            </div>


            {/* Product View */}

            <div className="widget-code">
              <div className="heading">
                <h4>Edit Widget</h4>
              </div>
              <div className="d-flex text-image-widget product-view align-items-start">
                <div className="img-text-wrap">
                  <div className="product-wrapper">
                    <div className="cross-button">
                      <Svg name="cross-button" />
                    </div>
                    <div className="img-wrapper">
                      <Slider {...imgSlider}>
                        <div>
                          <div className="img-in">
                            <img src={imgText} alt="" className="img-fluid" />
                          </div>
                        </div>
                        <div>
                          <div className="img-in">
                            <img src={imgText} alt="" className="img-fluid" />
                          </div>
                        </div>
                        <div>
                          <div className="img-in">
                            <img src={imgText} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </Slider>
                    </div>
                    <div className="product-text">
                      <div className="main-text">
                        <h3>Product Name</h3>
                        <div className="price-rating">
                          <h5>$55 </h5>
                          <div className="rating">
                            <div className="stars">
                              <div className="star rated"></div>
                              <div className="star rated"></div>
                              <div className="star rated"></div>
                              <div className="star rated-half"></div>
                              <div className="star"></div>
                            </div>
                            <div className="reviews">(3.5 stars) • 10 ratings</div>
                          </div>
                        </div>
                        <div className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut </div>
                      </div>
                      <div className="variant">
                        <div className="variant-heading">Variant</div>
                        <ul className="nav nav-tabs" id="productTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="product-tab-1" data-bs-toggle="tab" data-bs-target="#product-t-1" type="button" role="tab" aria-controls="product-t-1" aria-selected="true">Option One</button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="product-tab-2" data-bs-toggle="tab" data-bs-target="#product-t-2" type="button" role="tab" aria-controls="product-t-2" aria-selected="false">Option Two</button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="product-tab-3" data-bs-toggle="tab" data-bs-target="#product-t-3" type="button" role="tab" aria-controls="product-t-3" aria-selected="false">Option Three</button>
                          </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                          <div className="tab-pane fade show active" id="product-t-1" role="tabpanel" aria-labelledby="product-tab-1">
                            <div className="tab-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio autem ab labore in optio rerum, doloribus illo aliquid nemo molestiae incidunt saepe perspiciatis commodi cumque a quas. Illo, alias!</div>
                          </div>
                          <div className="tab-pane fade" id="product-t-2" role="tabpanel" aria-labelledby="product-tab-2">
                            <div className="tab-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio autem ab labore in optio rerum, doloribus illo aliquid nemo molestiae incidunt saepe perspiciatis commodi cumque a quas. Illo, alias!</div>
                          </div>
                          <div className="tab-pane fade" id="product-t-3" role="tabpanel" aria-labelledby="product-tab-3">
                            <div className="tab-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non odio autem ab labore in optio rerum, doloribus illo aliquid nemo molestiae incidunt saepe perspiciatis commodi cumque a quas. Illo, alias!</div>
                          </div>
                        </div>

                      </div>
                      <div className="accordion accordion-product-wrp" id="accordionProduct">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="accordionProductHeading1">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordionProductCollapse1" aria-expanded="true" aria-controls="accordionProductCollapse1">
                              Accordion Item #1
                            </button>
                          </h2>
                          <div id="accordionProductCollapse1" className="accordion-collapse collapse show" aria-labelledby="accordionProductHeading1" data-bs-parent="#accordionProduct">
                            <div className="accordion-body">
                              <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="accordionProductHeading2">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionProductCollapse2" aria-expanded="false" aria-controls="accordionProductCollapse2">
                              Accordion Item #2
                            </button>
                          </h2>
                          <div id="accordionProductCollapse2" className="accordion-collapse collapse" aria-labelledby="accordionProductHeading2" data-bs-parent="#accordionProduct">
                            <div className="accordion-body">
                              These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="accordionProductHeading3">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionProductCollapse3" aria-expanded="false" aria-controls="accordionProductCollapse3">
                              Accordion Item #3
                            </button>
                          </h2>
                          <div id="accordionProductCollapse3" className="accordion-collapse collapse" aria-labelledby="accordionProductHeading3" data-bs-parent="#accordionProduct">
                            <div className="accordion-body">
                              These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button-wrap">
                      <button className="btn btn-primary">Button</button>
                      <button className="btn btn-bordered">Button</button>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 widget-fields">
                  <FileUploadWidget />

                  <div className="slide-wrapper pb-2">
                    <div className="slide-list mb-3">
                      <div className="slide-title">Image slider</div>
                      <div className="slide-inner mb-0 pt-2">
                        <div class="form-text text-end mb-2 mt-0">Dimensions: 400 x 600px Supported format: PNG,JPEG,JPG</div>
                        <div className="form-group ">

                          <div className="img-list-wrapper">
                            <div className="label">Image 1</div>
                            <div class="d-flex align-items-center img-list">
                              <div class="flex-grow-1 pe-3">
                                <input className="form-control" type="file" id="image-1" />
                              </div>
                              <div class="action-button">
                                <button class="action-btn remove">
                                  <Svg name="option-remove-icon" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="img-list-wrapper">
                            <div className="label">Image 2</div>
                            <div class="d-flex align-items-center img-list">
                              <div class="flex-grow-1 pe-3">
                                <input className="form-control" type="file" id="image-2" />
                              </div>
                              <div class="action-button">
                                <button class="action-btn remove">
                                  <Svg name="option-remove-icon" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="img-list-wrapper">
                            <div className="label">Image 3</div>
                            <div class="d-flex align-items-center img-list">
                              <div class="flex-grow-1 pe-3">
                                <input className="form-control" type="file" id="image-3" />
                              </div>
                              <div class="action-button">
                                <button class="action-btn remove">
                                  <Svg name="option-remove-icon" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="img-list-wrapper">
                            <div className="label">Image 4</div>
                            <div class="d-flex align-items-center img-list">
                              <div class="flex-grow-1 pe-3">
                                <input className="form-control" type="file" id="image-4" />
                              </div>
                              <div class="action-button">
                                <button class="action-btn add">
                                  <Svg name="option-add-icon" />
                                </button>
                              </div>
                            </div>
                          </div>


                        </div>


                      </div>
                    </div>
                    <div className="slide-list mb-3">
                      <div className="slide-title">Text content</div>
                      <div className="slide-inner mb-0">
                        <div className="form-group mb-3">
                          <div className="label">Heading</div>
                          <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                        </div>
                        <div className="form-group mb-3">
                          <div className="label">Paragraph</div>
                          <div className="editor-wrapper">
                            ---Editor Here---
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="slide-list mb-3">
                      <div className="slide-title">Tabs</div>
                      <div className="tabs-list-wrapper brdr-top">
                        <div className="form-group mb-3">
                          <div className="label">Tab 1</div>
                          <div class="d-flex align-items-center img-list">
                            <div class="flex-grow-1 pe-3">
                              <div className="slide-inner mb-0">
                                <div className="form-group mb-3">
                                  <div className="label">Heading</div>
                                  <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                </div>
                                <div className="form-group">
                                  <div className="label">Paragraph</div>
                                  <div className="editor-wrapper">
                                    ---Editor Here---
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="action-button">
                              <button class="action-btn remove">
                                <Svg name="option-remove-icon" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <div className="label">Tab 2</div>
                          <div class="d-flex align-items-center img-list">
                            <div class="flex-grow-1 pe-3">
                              <div className="slide-inner mb-0">
                                <div className="form-group mb-3">
                                  <div className="label">Heading</div>
                                  <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                </div>
                                <div className="form-group">
                                  <div className="label">Paragraph</div>
                                  <div className="editor-wrapper">
                                    ---Editor Here---
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="action-button">
                              <button class="action-btn remove">
                                <Svg name="option-remove-icon" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <div className="label">Tab 3</div>
                          <div class="d-flex align-items-center img-list">
                            <div class="flex-grow-1 pe-3">
                              <div className="slide-inner mb-0">
                                <div className="form-group mb-3">
                                  <div className="label">Heading</div>
                                  <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                </div>
                                <div className="form-group">
                                  <div className="label">Paragraph</div>
                                  <div className="editor-wrapper">
                                    ---Editor Here---
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="action-button">
                              <button class="action-btn add">
                                <Svg name="option-add-icon" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="add-button mb-3">
                          <button className="add-slide-button">Add more tab</button>
                        </div>
                      </div>
                    </div>

                    <div className="slide-list mb-3">
                      <div className="slide-title">Accordion</div>
                      <div className="tabs-list-wrapper brdr-top">
                        <div className="form-group mb-3">
                          <div className="label">Accordion 1</div>
                          <div class="d-flex align-items-center img-list">
                            <div class="flex-grow-1 pe-3">
                              <div className="slide-inner mb-0">
                                <div className="form-group mb-3">
                                  <div className="label">Heading</div>
                                  <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                </div>
                                <div className="form-group">
                                  <div className="label">Paragraph</div>
                                  <div className="editor-wrapper">
                                    ---Editor Here---
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="action-button">
                              <button class="action-btn remove">
                                <Svg name="option-remove-icon" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <div className="label">Accordion 2</div>
                          <div class="d-flex align-items-center img-list">
                            <div class="flex-grow-1 pe-3">
                              <div className="slide-inner mb-0">
                                <div className="form-group mb-3">
                                  <div className="label">Heading</div>
                                  <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                </div>
                                <div className="form-group">
                                  <div className="label">Paragraph</div>
                                  <div className="editor-wrapper">
                                    ---Editor Here---
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="action-button">
                              <button class="action-btn remove">
                                <Svg name="option-remove-icon" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <div className="label">Accordion 3</div>
                          <div class="d-flex align-items-center img-list">
                            <div class="flex-grow-1 pe-3">
                              <div className="slide-inner mb-0">
                                <div className="form-group mb-3">
                                  <div className="label">Heading</div>
                                  <input type="text" name="" className="form-control" placeholder="Enter your heading here" />
                                </div>
                                <div className="form-group">
                                  <div className="label">Paragraph</div>
                                  <div className="editor-wrapper">
                                    ---Editor Here---
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="action-button">
                              <button class="action-btn add">
                                <Svg name="option-add-icon" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="add-button mb-3">
                          <button className="add-slide-button">Add accordion</button>
                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <div className="label button-label" data-bs-toggle="collapse" data-bs-target="#collapsePrimary" aria-expanded="false" aria-controls="collapsePrimary">Primary button</div>
                        <div className="collapse" id="collapsePrimary">
                          <div className="card card-body buttons-body">
                            <div className="field-wrapper">
                              <div className="row mb-3">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">Text on button</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>
                              <div className="row">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">URL</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <div className="label button-label" data-bs-toggle="collapse" data-bs-target="#collapseSecondary" aria-expanded="false" aria-controls="collapseSecondary">Secondary button</div>
                        <div className="collapse" id="collapseSecondary">
                          <div className="card card-body buttons-body">
                            <div className="field-wrapper">
                              <div className="row mb-3">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">Text on button</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>
                              <div className="row">
                                <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">URL</label>
                                <div className="col-sm-7">
                                  <input type="text" className="form-control" id="inputEmail3" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                    <button type="button" className="btn btn-primary" >Save widget</button>
                    <button type="button" className="btn btn-bordered color-cancel ms-3" onClick={closeModal}>Cancel</button>
                  </div>

                </div>
              </div>

            </div>

          </Modal.Body>
        </Modal>


      </div>
    </>
  );
}
