import React from 'react'
import Svg from "../../common/Svg";

const FormFields = ({ field, setFormFields, index }) => {

    if (field.type === 1) {
        return (
            <div className="slide-list mb-3">
                <div className="slide-title">
                    Text filed
                </div>
                <div className="field-wrapper d-flex align-items-center">
                    <div className="field-inner flex-grow-1 pe-3">
                        <div className="slide-inner mb-0">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <div className="d-flex justify-content-between">
                                            <div className="label">Label </div>
                                        </div>
                                        <input type="text" name="" className="form-control" placeholder="Full name" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <div className="d-flex justify-content-between">
                                            <div className="label">Name </div>
                                        </div>
                                        <input type="text" name="" className="form-control" placeholder="Full name" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <div className="label">Placeholder</div>
                                        <input type="text" name="" className="form-control" placeholder="Enter your full name" />
                                    </div>
                                </div>

                            </div>
                            <div className="more-setting">
                                <button className="btn more-setting-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#setting1" aria-expanded="false" aria-controls="setting1">
                                    More settings</button>
                                <div className="collapse" id="setting1">
                                    <div className="setting-inner">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group mb-3">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="label">Validation </div>
                                                    </div>
                                                    <select className="form-select">
                                                        <option value={1}>Contains Validations</option>
                                                        <option value={2}>Doesn’t contains</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-3">
                                                    <div className="label">Enter characters/formula</div>
                                                    <input type="text" name="" className="form-control" placeholder="Enter text" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-3">
                                                    <div className="label">Error message</div>
                                                    <input type="text" name="" className="form-control" placeholder="Custom error text message" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group mb-3">
                                                    <div className="d-flex">
                                                        <div className="label me-2">Is required </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="switch12" />
                                                            <label className="form-check-label" htmlFor="switch12"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="remove-button"><Svg name="trash-icon" /></div>
                </div>
            </div>
        )
    }
    return (
        <div>FormFields</div>
    )
}

export default FormFields