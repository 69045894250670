import React, { useEffect, useState } from "react";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { editMetaspace, getTemplateDetailById } from "../../actions/metaSpace";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { Formik } from "formik";

import { MetaspaceSchema } from "../../Schema/Metaspace";

import { useRef } from "react";

export default function EditMetaspace() {

    const [isCreatingMetaspace, setIsCreatingMetaspace] = useState(false);
    const [formValue, setFormValue] = useState({
        name: '',
        description: '',
        embedCode: '',
        thumbnail: null,
        structure: '',
        build: 'build',
        metaspace_type: "simple-metaspace",
        link: "",
        templateCode: "",
        isActive: true,
    });


    const navigate = useNavigate();

    let { state } = useLocation();

    const formikRef = useRef();


    const handleEditMetaspace = async (data) => {
        setIsCreatingMetaspace(true);
        try {
            
            const res = await editMetaspace(data);
            if (res.statusCode === 200) {
                toast.success(res.msg);
                navigate("/metaspaces");
            } else {
                toast.error(res.msg);
            }
        } catch (error) {
            toast.error(error.msg);
            setIsCreatingMetaspace(false);
        }
    };

    const fetchMetaspaceData = async (data) => {
        try {
            const res = await getTemplateDetailById(data);
            if (res.statusCode === 200) {
                console.log(res.data[0]);
                const metaSpaceData = res.data[0];
                setFormValue({
                    name: metaSpaceData.template_name,
                    description: metaSpaceData.description,
                    embedCode: metaSpaceData.embed_code,
                    thumbnail: null,
                    structure: metaSpaceData.template_structure,
                    build: metaSpaceData.build_name,
                    metaspace_type: metaSpaceData.metaspace_type,
                    link: metaSpaceData.link,
                    templateCode: metaSpaceData.template_code,
                    isActive: metaSpaceData.is_active ? true : false,
                })
            } else {
                toast.error(res.msg);
            }
        } catch (error) {
            toast.error(error.msg);
            setIsCreatingMetaspace(false);
        }
    }

    useEffect(() => {
        if (state.template_id) {
            const payload = {
                t_id: state.template_id,
                build_id: state.template_build_id
            }
            fetchMetaspaceData(payload)

        } else {
            toast.error("MetaSpace not found");
            navigate("/metaspaces");
        }

    }, [state, navigate])




    return (
        <>
            <ToastContainer position="top-right" />
            <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
                <DashboardHeader />
                <div id="viewport" className="d-flex flex-grow-1">
                    <DashboardSidebar />
                    <div className="flex-grow-1 page-content-flex">
                        <Formik
                            innerRef={formikRef}
                            initialValues={formValue}
                            enableReinitialize
                            validationSchema={MetaspaceSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                let dataToSend = {
                                    template_name: values.name,
                                    description: values.description,
                                    template_code: values.templateCode,
                                    thumbnail: values.thumbnail,
                                    template_structure: values.structure,
                                    build_name: values.build,
                                    metaspace_type: values.metaspace_type,
                                    embed_code: values.embedCode,
                                    link: values.link,
                                    is_active: values.isActive ? 1 : 0,
                                    template_type: 1,
                                    build_id: state.template_build_id,
                                    template_id: state.template_id,
                                };
                                handleEditMetaspace(dataToSend);
                                resetForm();
                                setSubmitting(false);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="page-content-wrap">
                                        <div className="heading-section page-heading d-md-flex exp-button-wrapper">
                                            <div className="flex-grow-1">
                                                <nav
                                                    aria-label="breadcrumb"
                                                    className="vmersive-breadcrumbs"
                                                >
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <Link to="/metaspaces">Metaspace</Link>
                                                        </li>
                                                        <li
                                                            className="breadcrumb-item active"
                                                            aria-current="page"
                                                        >
                                                            Edit Metaspace
                                                        </li>
                                                    </ol>
                                                </nav>
                                                <h1>Edit Metaspace</h1>
                                                <p className="pt-2">
                                                    Here you can edit Metaspace

                                                </p>
                                            </div>

                                        </div>

                                        <div className="exp-button-wrapper-outer">
                                            <div className="page-wrapper">
                                                <div className="new-experience-wrap exp-button-wrapper">
                                                    <div className="expt-input-wrapper d-md-flex">
                                                        <h6 className="mb-0 exp-name">Metaspace name</h6>
                                                        <div className="flex-grow-1 input-outer">
                                                            <div className="inputs-wrap account-info">
                                                                <div className="mb-2 position-relative">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control title"
                                                                        placeholder="Enter your Metaspace name here"
                                                                        name="name"
                                                                        value={values.name}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.name &&
                                                                        touched.name &&
                                                                        errors.name && (
                                                                            <div className="validation-msg">
                                                                                {errors.name &&
                                                                                    touched.name &&
                                                                                    errors.name}
                                                                            </div>
                                                                        )}

                                                                    <br />

                                                                </div>
                                                                <div className="mb-2 position-relative">
                                                                    <div className="mb-0 position-relative">
                                                                        <textarea
                                                                            className="form-control"
                                                                            placeholder="Add description"
                                                                            style={{ height: 60 }}
                                                                            name="description"
                                                                            defaultValue={values.description}
                                                                            onChange={handleChange}
                                                                        />
                                                                        {errors.description &&
                                                                            touched.description &&
                                                                            errors.description && (
                                                                                <div className="validation-msg">
                                                                                    {errors.description &&
                                                                                        touched.description &&
                                                                                        errors.description}
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="expt-input-wrapper d-md-flex">
                                                        <h6 className="mb-0 exp-name">Template Code</h6>
                                                        <div className="flex-grow-1 input-outer">
                                                            <div className="inputs-wrap account-info">
                                                                <div className="mb-2 position-relative">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter your Template code here"
                                                                        name="templateCode"
                                                                        value={values.templateCode}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.templateCode &&
                                                                        touched.templateCode &&
                                                                        errors.templateCode && (
                                                                            <div className="validation-msg">
                                                                                {errors.templateCode &&
                                                                                    touched.templateCode &&
                                                                                    errors.templateCode}
                                                                            </div>
                                                                        )}

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="expt-input-wrapper d-md-flex">
                                                        <h6 className="mb-0 exp-name">Embed Code</h6>
                                                        <div className="flex-grow-1 input-outer">
                                                            <div className="inputs-wrap account-info">
                                                                <div className="mb-2 position-relative">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter your Embed code here"
                                                                        name="embedCode"
                                                                        value={values.embedCode}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.embedCode &&
                                                                        touched.embedCode &&
                                                                        errors.embedCode && (
                                                                            <div className="validation-msg">
                                                                                {errors.embedCode &&
                                                                                    touched.embedCode &&
                                                                                    errors.embedCode}
                                                                            </div>
                                                                        )}

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="expt-input-wrapper d-md-flex">
                                                        <h6 className="mb-0 exp-name">structure</h6>
                                                        <div className="flex-grow-1 input-outer">
                                                            <div className="inputs-wrap account-info">
                                                                <div className="mb-2 position-relative">
                                                                    <textarea
                                                                        className="form-control"
                                                                        placeholder="Add Metaspace Structure"
                                                                        style={{ height: 60 }}
                                                                        name="structure"
                                                                        defaultValue={values.structure}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.structure &&
                                                                        touched.structure &&
                                                                        errors.structure && (
                                                                            <div className="validation-msg">
                                                                                {errors.structure &&
                                                                                    touched.structure &&
                                                                                    errors.structure}
                                                                            </div>
                                                                        )}

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="expt-input-wrapper d-md-flex">
                                                        <h6 className="mb-0 exp-name">Select Metaspace type</h6>
                                                        <div className="flex-grow-1 input-outer">
                                                            <div className="inputs-wrap account-info">
                                                                <h4 className="mb-2 position-relative">
                                                                    <select
                                                                        className="form-select form-control"
                                                                        name="metaspace_type"
                                                                        value={values.metaspace_type}
                                                                        onChange={handleChange}
                                                                    >
                                                                        {/* Options for Build */}
                                                                        <option value="simple-metaspace">Simple metaspace</option>
                                                                        <option value="aerial-view">Aerial view</option>
                                                                        {/* Add other options as needed */}
                                                                    </select>
                                                                </h4>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="expt-input-wrapper d-md-flex">
                                                        <h6 className="mb-0 exp-name">Thumbnail Upload</h6>
                                                        <div className="flex-grow-1 input-outer">
                                                            <div className="inputs-wrap account-info">
                                                                <h4 className="mb-2 position-relative">
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        placeholder="Enter your Metaspace name here"
                                                                        name="thumbnail"
                                                                        value={values.thumbnail}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.thumbnail &&
                                                                        touched.thumbnail &&
                                                                        errors.thumbnail && (
                                                                            <div className="validation-msg">
                                                                                {errors.thumbnail &&
                                                                                    touched.thumbnail &&
                                                                                    errors.thumbnail}
                                                                            </div>
                                                                        )}

                                                                </h4>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="expt-input-wrapper d-md-flex">
                                                        <h6 className="mb-0 exp-name">Link</h6>
                                                        <div className="flex-grow-1 input-outer">
                                                            <div className="inputs-wrap account-info">
                                                                <div className="mb-2 position-relative">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter your Link here"
                                                                        name="link"
                                                                        value={values.link}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="expt-input-wrapper d-md-flex">
                                                        <h6 className="mb-0 exp-name">Active</h6>
                                                        <div className="flex-grow-1 input-outer">
                                                            <div className="inputs-wrap account-info">
                                                                <div className="mb-2 position-relative">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="isActive"
                                                                        value={values.isActive}
                                                                        onChange={handleChange}
                                                                        checked={values.isActive}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="expt-input-wrapper d-md-flex">
                                                        <h6 className="mb-0 exp-name">Select build</h6>
                                                        <div className="flex-grow-1 input-outer">
                                                            <div className="inputs-wrap account-info">
                                                                <h4 className="mb-2 position-relative">
                                                                    <select
                                                                        className="form-select form-control"
                                                                        name="build"
                                                                        value={values.build}
                                                                        onChange={handleChange}
                                                                    >
                                                                        {/* Options for Build */}
                                                                        <option value="Build">Select from previous</option>
                                                                        <option value="Create Build"> Create Experience</option>
                                                                        {/* Add other options as needed */}
                                                                    </select>
                                                                </h4>


                                                            </div>

                                                        </div>
                                                    </div>
                                                    <button
                                                        className="btn btn-primary"
                                                        type="submit"
                                                        disabled={isCreatingMetaspace}
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
}
