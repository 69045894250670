import React, { useState, useEffect } from 'react'
import Svg from "../../common/Svg";
import Modal from 'react-bootstrap/Modal';
import FileUploadWidget from '../add-media/FileUploadWidget';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import imgText from "../../../assets/images/image-text.jpg";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const SliderImageModal = ({
    teleportData,
    onSave,
    show,
    handleClose,
}) => {

    const [sliders, setSliders] = useState([{ text: "", file: {} }]);

    useEffect(() => {
        setSliders(teleportData?.widget_details?.sliders || [{ text: "", file: {} }]);

        console.log(teleportData?.widget_details?.file);
    }, [teleportData]);


    const handleSave = () => {
        const widgetDetails = {
            sliders: sliders,
        }
        onSave(widgetDetails);
        handleClose();
    }

    const handleAddSliders = () => {
        const slider = sliders.length;
        if (slider > 4) return;
        setSliders([...sliders, { text: "", file: {} }]);
    }

    const handleChangeSliderData = (index, data, key) => {
        setSliders(preState => {
            const newState = [...preState];
            newState[index][key] = data;
            return newState;
        });
    }

    const handleRemoveSlider = (index) => {
        if (sliders.length === 1) return;
        setSliders(preState => {
            const newState = [...preState];
            newState.splice(index, 1);
            return newState;
        });
    }


    return (
        <div>
            <Modal show={show === "sliderImageTextModal"}
                onHide={handleClose}
                className="customvar-modal modal fade fileUpload uploadMedia"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-details-width"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="widget-code">
                        <div className="heading">
                            <h4>Edit Widget</h4>
                        </div>
                        <div className="d-flex text-image-widget text-video-widget align-items-start">
                            <div className="img-text-wrap">
                                <div className="slider-wrapper">
                                    <Slider {...settings}>
                                        {sliders.map((item, index) =>
                                            <div key={index}>
                                                <div className="slider-inner">
                                                    <div className="video-wrap">
                                                        <div className="img-wrap">
                                                            <img src={item.file.link || imgText} alt="" className="img-fluid" />
                                                        </div>
                                                        <button className="cross-button" type="button" onClick={() => handleRemoveSlider(index)}>
                                                            <Svg name="cross-button" />
                                                        </button>

                                                    </div>
                                                    <div className="text-outer">
                                                        <div className="text-wrap">
                                                            <div dangerouslySetInnerHTML={{ __html: item.text }} className='editor'></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* Add more slides as needed */}
                                    </Slider>
                                </div>
                            </div>
                            <div className="flex-grow-1 widget-fields slide-view">
                                <div className="slide-wrapper">
                                    {sliders.map((item, index) =>
                                        <div className="slide-list" key={index}>
                                            <div className="slide-title">
                                                Slide {index + 1}
                                            </div>
                                            <div className="slide-inner">
                                                <FileUploadWidget
                                                    currentMediaSpotId={null}
                                                    selectedSpotAreaId={null}
                                                    currentMediaSpotData={item.file}
                                                    changeMediaSpotData={(data) => {
                                                        console.log(data);
                                                        handleChangeSliderData(index, data, "file");
                                                    }}
                                                    closeModal={handleClose}
                                                    isOpenModal={true}
                                                />
                                                <div className="form-group mb-3">
                                                    <div className="label">Insert text here</div>
                                                    <div className="editor-wrapper">
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={item.text}
                                                            config={{
                                                                removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table", "TableToolbar"]
                                                            }}
                                                            onChange={(event, editor) => {
                                                                let data = editor.getData();
                                                                handleChangeSliderData(index, data, "text");
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}

                                </div>
                                <div className="add-button mb-3">
                                    <button className="add-slide-button" onClick={handleAddSliders} disabled={sliders.length > 4} >Add Slide</button>
                                </div>

                                <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                                    <button type="button" className="btn btn-primary" onClick={handleSave} >Save widget</button>
                                    <button type="button" className="btn btn-bordered color-cancel ms-3" onClick={handleClose}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default SliderImageModal