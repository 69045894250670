import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";

import Copyright from "../common/Copyright";
import Svg from "../common/Svg";

import { getToken, login } from "../../actions/auth.js";
import {LoginSchema,forgotEmailSchema} from "../../Schema/Auth";

export default function Login() {
  const navigate = useNavigate();
  const [showEye, setShowEye] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [error, setError] = useState("");
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
     validationSchema: LoginSchema,
    onSubmit: (values) => {
      onLogin(values);
    },
  });
  const onLogin = async (data) => {
    let res = localStorage.getItem("token");

    // if (!localStorage.getItem("token")) {
    res = await getToken();
    // }

    setIsFormSubmit(true);
    try {
      const loginResponse = await login(data, res.token);
      if (loginResponse.statusCode === 200) {
        // toast.success(loginResponse.msg);
        setError("");
        const loggedinUserData = JSON.stringify(loginResponse.data);
        localStorage.setItem("loggedinUserData", loggedinUserData);
        setTimeout(() => {
          navigate("/events/list");
        }, 1000);
      } else {
        toast.error(loginResponse.msg);
        //setError(loginResponse.msg);
      }
    } catch (err) {
      toast.error(err.response.data.msg);
    }
    setIsFormSubmit(false);
  };

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column site-main-wrapper login-page">
        <div className="flex-grow-1 main-content-wrap d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="form-outer-wrapper">
              <div className="logo-wrap text-center">
                <Link to="/#0t" className="home-logo">
                  <Svg name="logo" />
                </Link>
              </div>
              <div className="form-wrapper mb__20">
                <div className="form-heading text-center">
                  <h3>Sign in</h3>
                  {/* <h3>Log in to get started</h3> */}
                  {/* <p>Use your work email to log in or get started</p> */}
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="field-wrapper">

                    <div className="form-floating mb__20 position-relative">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      <label htmlFor="floatingInput" className="mb-0">Email</label>
                      {formik.errors.email && (
                        <div className="validation-msg">
                          {formik.errors.email}
                        </div>
                      )}
                    </div>

                    <div className="form-floating mb__20 position-relative">
                      {/* Add Class error to this div once error occurs and if its valid, add class success*/}
                      <input
                        type={showEye ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      <label htmlFor="floatingInput" className="mb-0">Password</label>

                      <span
                        className={`forgot-wrap ${showEye ? "text" : "password"
                          }`}
                        onClick={() => {
                          setShowEye(!showEye);
                        }}
                      >
                        <i className="fa fa-eye" aria-hidden="true" />
                      </span>
                      {formik.errors.password && (
                        <div className="validation-msg">
                          {formik.errors.password}
                        </div>
                      )}
                      <div className="login-validation-msg">
                        {error !== "" && error}
                      </div>
                    </div>

                    <div className="additional-info mb__20 d-flex justify-content-between">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="remember"
                        />
                        <label className="form-check-label" htmlFor="remember">
                          Remember me
                        </label>
                      </div>

                      <Link to="/forgot-password">
                        <b>Forgot Password?</b>
                      </Link>
                    </div>
                  </div>
                  <div className="button-wrap">
                    <button
                      disabled={isFormSubmit}
                      type="submit"
                      className="btn-primary btn w-100"
                    >
                      {isFormSubmit ? (
                        <>
                          <span
                            className="white-spinner spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        "Next"
                      )}
                    </button>
                  </div>
                </form>
                <div className="outer-form-text">
                  <p className="mb-0 text-center">
                    By signing in, I agree to the Vmersive's <Link to="/privacy-policies">Privacy Statement</Link> and <Link to="/terms">Terms of Service</Link>.
                  </p>
                </div>
              </div>
              <Copyright />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
