import Svg from '../../common/Svg'
import React, { useEffect, useState, memo } from "react";
import empty from "../../../assets/images/empty.jpg";
import {
    getMediaList,
} from "../../../actions/media";
import { s3MediaUrl } from "../../../utils/s3FileUpload";
import { toast } from "react-toastify";
import { getThumbnail } from "../../../utils/imageThumbnail";


const asset_typ_reverse = {
    1: "mp4",
    2: "png",
    3: "jpg",
    11: "jpeg",
    4: "gif",
    5: "html",
    6: "youtube link",
    7: "ppt",
    8: "pdf",
    9: "svg",
    10: "vimeo link",
    12: "pptx",
    13: "doc",
    14: "docx",
};

const FileUploadWidget = ({
    currentMediaSpotId,
    currentMediaSpotData,
    selectedSpotAreaId,
    changeMediaSpotData,
    closeModal,
}) => {

    const [image, setImage] = useState(null);
    const [imageDimensions, setImageDimensions] = useState({});
    const [isOpenModal, setIsOpenModal] = useState(true);

    const [serverParams, setServerParams] = useState({
        startingLimit: 0,
        resultsPerPage: 10,
        parent_id: "",
        file_type: "",
        search: "",
        sort_by: "add_date_time",
        sort_order: "desc",
    });
    const [isFetching, setIsFetching] = useState(false);
    const [mediaData, setMediaData] = useState([]);
    const [totalMediaData, setTotalMediaData] = useState("");
    const [selectedMedia, setselectedMedia] = useState(null);

    const [currentTabId, setCurrentTabId] = useState(1);
    const [isUpdating, setIsUpdating] = useState(false);
    const [folderData, setFolderData] = useState([]);

    const getMediaFolderList = async (serverParams, action) => {
        setIsFetching(true);
        try {
            const mediaListResponse = await getMediaList(serverParams);
            if (mediaListResponse.statusCode === 200) {
                let data = mediaListResponse.data;
                if (action === "loadMore") {
                    setMediaData((mediaData) => [...mediaData, ...data]);
                } else {
                    setMediaData(data);
                }
                setTotalMediaData(mediaListResponse.total_count);
                mediaListResponse.data.map((data) => {
                    if (data.id === currentMediaSpotData.asset_id) {
                        setselectedMedia(data);
                    }
                    if (data.id === currentMediaSpotData.parent_id) {
                        setFolderData([...folderData, data]);
                    }
                });
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setIsFetching(false);
        }
    };

    const handleSearch = (event) => {
        const tempParams = { ...serverParams, search: event.target.value };
        setServerParams(tempParams);
        getMediaFolderList(tempParams);
    };


    const getFolderMediaList = (media) => {
        if (media.is_folder === 1) {
            setFolderData([...folderData, media]);
            let tempParams = { ...serverParams, parent_id: media.id };
            setServerParams(tempParams);
            getMediaFolderList(tempParams, "");
        }
    };

    const goToBackAllMediaList = () => {
        setFolderData([]);
        setServerParams({
            ...serverParams,
            parent_id: "",
        });
        getMediaFolderList({
            ...serverParams,
            parent_id: "",
        });
    };


    const onUploadMedia = () => {
        setIsUpdating(true);
        try {
            if (currentTabId === 1) {
                let dataForSend = {
                    id: selectedMedia.id,
                    file_type: selectedMedia.asset_type_id,
                    link:
                        selectedMedia.asset_type_id == 6
                            ? selectedMedia.link
                            : s3MediaUrl +
                            "/" +
                            selectedMedia.id +
                            "." +
                            asset_typ_reverse[selectedMedia.asset_type_id],
                    name: selectedMedia.user_asset_library_name,
                    parent_id: selectedMedia.parent_id,
                    dimensions: `${imageDimensions.height} X ${imageDimensions.width}`,
                };
                changeMediaSpotData(dataForSend);
                // setselectedMedia(null);
            }
        } catch (error) {
            console.log(error);
        } finally {
            // setMediaData([]);
            setServerParams({
                startingLimit: 0,
                resultsPerPage: 10,
                parent_id: "",
                file_type: "",
                search: "",
                sort_by: "add_date_time",
                sort_order: "desc",
            });
            setIsUpdating(false);
            setIsOpenModal(false);
        }
    };


    const loadImage = (setImageDimensions, imageUrl) => {
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
            setImageDimensions({
                height: img.height,
                width: img.width,
            });
        };
        img.onerror = (err) => {
            console.log("img error");
            console.error(err);
        };
    };


    useEffect(() => {
        getMediaFolderList(serverParams);
        if ([2, 3, 4, 9, 11].includes(currentMediaSpotData.media_type)) {
            loadImage(setImageDimensions, currentMediaSpotData.media_url);
        }
    }, [currentMediaSpotData]);

    useEffect(() => {
        if (isOpenModal) {
            console.log("isOpenModal", isOpenModal);
            setFolderData([]);
            getMediaFolderList({
                startingLimit: 0,
                resultsPerPage: 10,
                parent_id: "",
                file_type: "",
                search: "",
                sort_by: "add_date_time",
                sort_order: "desc",
            });
        } else {
            setselectedMedia(null);
            setImageDimensions({});
        }
    }, [isOpenModal]);

    useEffect(() => {
        console.log(folderData);
        if (folderData.length > 0) {
            const tempParams = {
                ...serverParams,
                parent_id: folderData[folderData.length - 1]["id"],
            };
            setServerParams(tempParams);
            getMediaFolderList(tempParams);
        }
    }, [folderData]);


    useEffect(() => {
        return () => {
            console.log("unmoint");
            setServerParams({
                startingLimit: 0,
                resultsPerPage: 10,
                parent_id: "",
                file_type: "",
                search: "",
                sort_by: "add_date_time",
                sort_order: "desc",
            });
            // setCurrentTabId(1);
            setImage(null);
            setIsOpenModal(false);
            setFolderData([]);
            console.log("closeModal");
        }
    }, []);


    return (
        <div className="file-upload-mod">
            <div className="file-upload form-group mb-3">
                <div className="label">Upload an image</div>
                <div className="input-group mb-2 input-file">
                    <button className="input-group-text" id="basic-addon1">Choose file</button>
                    <input type="text" className="form-control" aria-describedby="basic-addon1" readOnly />
                </div>
                <div className="form-text">Dimensions: 400 x 600px | Dimensions: 400 x 600px Supported format: PNG,JPEG,JPG</div>
            </div>
            {/* File Grid View */}
            <div className="file-grid-view-outer mb-3">
                <div className="table-wrapper-view">
                    <div className="heading-from-media">
                        <h3>From media </h3>
                    </div>
                    <div className={"formstyle"}>
                        <div className="tab-3-wrapper">
                            <div className="page-wrapper">
                                <div className="tab-content" id="meetingsContent">
                                    <div
                                        className={
                                            currentTabId === 1
                                                ? "tab-pane fade show active"
                                                : "tab-pane fade"
                                        }
                                        id="popup-tab-1"
                                        role="tabpanel"
                                        aria-labelledby="popup-tab-1-tab"
                                    >
                                        <div className="table-wrapper-view">
                                            <div className="tab-search search-filter d-flex align-items-center">
                                                <div className="flex-grow-1 search-filter-padding pe-0">
                                                    <input
                                                        type="text"
                                                        className="form-control custom-search-input"
                                                        placeholder="Search files"
                                                        value={serverParams.search}
                                                        onChange={handleSearch}
                                                    />
                                                </div>
                                            </div>
                                            {folderData.length > 0 && (
                                                <nav
                                                    className="folder-breadcrumbs"
                                                    aria-label="breadcrumb"
                                                >
                                                    <ol className="breadcrumb">
                                                        <li
                                                            className="breadcrumb-item"
                                                            onClick={() => {
                                                                goToBackAllMediaList();
                                                            }}
                                                        >
                                                            <a>Media</a>
                                                        </li>
                                                        {folderData.map((folder, folderIndex) => (
                                                            <li
                                                                className="breadcrumb-item active cursor-pointer"
                                                                aria-current="page"
                                                                key={folderIndex}
                                                                onClick={() => {
                                                                    setFolderData(
                                                                        folderData.slice(0, folderIndex + 1)
                                                                    );
                                                                }}
                                                            >
                                                                <Svg name="folder-icon" />{" "}
                                                                {folder.user_asset_library_name}
                                                            </li>
                                                        ))}
                                                    </ol>
                                                </nav>
                                            )}
                                            <div className="file-grid-view modal-grid-view">
                                                <div className="row">
                                                    <div className="col-md-8 col-lg-9">
                                                        <div className="row">
                                                            {isFetching ? (
                                                                <>
                                                                    <span
                                                                        className="spinner-border spinner-border-sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="visually-hidden">
                                                                        Loading...
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {mediaData.length > 0 ? (
                                                                        <>
                                                                            {mediaData.length > 0 &&
                                                                                mediaData.map((media, mediaIndex) => (
                                                                                    <div
                                                                                        className="col position-relative up-media"
                                                                                        key={mediaIndex}
                                                                                        onClick={() => {
                                                                                            getFolderMediaList(media);
                                                                                        }}
                                                                                    >
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            id={`template-${mediaIndex}`}
                                                                                            name="chooseTemplate"
                                                                                            defaultChecked={
                                                                                                selectedMedia &&
                                                                                                selectedMedia.id === media.id
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                if (e.target.value === "on") {
                                                                                                    if (
                                                                                                        [
                                                                                                            "png",
                                                                                                            "jpg",
                                                                                                            "jpeg",
                                                                                                            "gif",
                                                                                                            "svg",
                                                                                                        ].includes(
                                                                                                            media.user_asset_library_name
                                                                                                                .split(".")
                                                                                                                .pop()
                                                                                                        )
                                                                                                    ) {
                                                                                                        // openCropModal();
                                                                                                        let imgUrl = `${s3MediaUrl}/${media.id
                                                                                                            }.${asset_typ_reverse[
                                                                                                            media.asset_type_id
                                                                                                            ]
                                                                                                            }`;
                                                                                                        loadImage(
                                                                                                            setImageDimensions,
                                                                                                            imgUrl
                                                                                                        );
                                                                                                    }
                                                                                                    setselectedMedia(media);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <label
                                                                                            className="file-wrapper"
                                                                                            htmlFor={`template-${mediaIndex}`}
                                                                                        >
                                                                                            <div className="icon-wrap">
                                                                                                {media.is_folder === 1 && (
                                                                                                    <Svg name="folder-icon" />
                                                                                                )}
                                                                                                {media.is_folder === 0 && (
                                                                                                    <>
                                                                                                        {media.asset_type_id && (
                                                                                                            <>
                                                                                                                {media.asset_type_id ===
                                                                                                                    6 &&
                                                                                                                    getThumbnail(
                                                                                                                        media.link
                                                                                                                    ) && (
                                                                                                                        <img
                                                                                                                            src={getThumbnail(
                                                                                                                                media.link
                                                                                                                            )}
                                                                                                                            alt={
                                                                                                                                media.user_asset_library_name
                                                                                                                            }
                                                                                                                        />
                                                                                                                    )}
                                                                                                                {(media.asset_type_id ===
                                                                                                                    7 ||
                                                                                                                    media.asset_type_id ===
                                                                                                                    12) && (
                                                                                                                        <Svg name="ppt-icon" />
                                                                                                                    )}
                                                                                                                {media.asset_type_id ===
                                                                                                                    8 && (
                                                                                                                        <Svg name="pdf-icon" />
                                                                                                                    )}
                                                                                                                {media.asset_type_id ===
                                                                                                                    10 && (
                                                                                                                        <Svg name="vimeo-icon-list" />
                                                                                                                    )}
                                                                                                                {[
                                                                                                                    "png",
                                                                                                                    "jpg",
                                                                                                                    "jpeg",
                                                                                                                    "gif",
                                                                                                                    "svg",
                                                                                                                ].includes(
                                                                                                                    media.user_asset_library_name
                                                                                                                        .split(".")
                                                                                                                        .pop()
                                                                                                                ) && (
                                                                                                                        <img
                                                                                                                            src={`${s3MediaUrl}/${media.id
                                                                                                                                }.${asset_typ_reverse[
                                                                                                                                media
                                                                                                                                    .asset_type_id
                                                                                                                                ]
                                                                                                                                }`}
                                                                                                                            alt=""
                                                                                                                            className="img-fluid"
                                                                                                                            onError={({
                                                                                                                                currentTarget,
                                                                                                                            }) => {
                                                                                                                                currentTarget.onerror =
                                                                                                                                    null; // prevents looping
                                                                                                                                currentTarget.src =
                                                                                                                                    empty;
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    )}
                                                                                                                {["mp4"].includes(
                                                                                                                    media.user_asset_library_name
                                                                                                                        .split(".")
                                                                                                                        .pop()
                                                                                                                ) && (
                                                                                                                        <img
                                                                                                                            src={`${s3MediaUrl}/${media.id
                                                                                                                                }.${asset_typ_reverse[
                                                                                                                                media
                                                                                                                                    .asset_type_id
                                                                                                                                ]
                                                                                                                                }.jpg`}
                                                                                                                            alt=""
                                                                                                                            className="img-fluid"
                                                                                                                            onError={({
                                                                                                                                currentTarget,
                                                                                                                            }) => {
                                                                                                                                currentTarget.onerror =
                                                                                                                                    null; // prevents looping
                                                                                                                                currentTarget.src =
                                                                                                                                    empty;
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    )}
                                                                                                            </>
                                                                                                        )}
                                                                                                        {(media.asset_type_id ===
                                                                                                            13 ||
                                                                                                            media.asset_type_id ===
                                                                                                            14) && (
                                                                                                                <Svg name="doc-file-icon" />
                                                                                                            )}
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                            <div
                                                                                                custom-attr={
                                                                                                    media.asset_type_id
                                                                                                }
                                                                                                className="text-wrap"
                                                                                            >
                                                                                                <p
                                                                                                    title={
                                                                                                        media.user_asset_library_name
                                                                                                    }
                                                                                                >
                                                                                                    {" "}
                                                                                                    {
                                                                                                        media.user_asset_library_name
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                ))}
                                                                        </>
                                                                    ) : (
                                                                        "No Data Found"
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="text-center">
                                                            {totalMediaData === mediaData.length ? (
                                                                <></>
                                                            ) : (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary mx-auto"
                                                                    onClick={() => {
                                                                        let tempParams = {
                                                                            ...serverParams,
                                                                            startingLimit:
                                                                                serverParams.startingLimit + 10,
                                                                        };
                                                                        setServerParams(tempParams);
                                                                        getMediaFolderList(tempParams, "loadMore");
                                                                    }}
                                                                >
                                                                    Load More
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer btn-160 justify-content-end">
                    <button type="button" className="btn btn-primary"
                        disabled={isUpdating}
                        onClick={() => {
                            onUploadMedia();
                        }}
                    >
                        Select
                    </button>
                    <button type="button" className="btn btn-bordered color-cancel ms-3"
                        disabled={isUpdating}
                        onClick={() => {
                            // setServerParams({
                            //     startingLimit: 0,
                            //     resultsPerPage: 10,
                            //     parent_id: "",
                            //     file_type: "",
                            //     search: "",
                            //     sort_by: "add_date_time",
                            //     sort_order: "desc",
                            // });
                            // // setCurrentTabId(1);
                            // setImage(null);
                            // setIsOpenModal(false);
                            // setFolderData([]);
                            console.log("closeModal");
                            closeModal();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
            {/* <div className="form-group mb-3">
                <div className="label text-center">Or</div>
                <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="Enter a video/image url"
                />
            </div> */}
        </div>
    )
}


FileUploadWidget.defaultProps = {
    currentMediaSpotId: null,
    currentMediaSpotData: {},
    selectedSpotAreaId: null,
    changeMediaSpotData: () => { },
    closeModal: () => { },
};

export default memo(FileUploadWidget);
