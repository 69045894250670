import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("This is not a valid email")
    .required("This is required"),
  password: Yup.string()
    .required("This is required")
    .min(6, "Password is too short - should be 6 chars minimum.")
    //.matches(/[0-9]/, "Password requires a number")
    //.matches(/[a-z]/, "Password requires a lowercase letter")
    // .matches(/[A-Z]/, "Password requires an uppercase letter")
    // .matches(/[^\w]/, "Password requires a symbol"),
});

export const forgotEmailSchema = Yup.object().shape({
  email: Yup.string().email("This is not a valid email").required("Email is required"),
});
