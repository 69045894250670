import React, { useEffect, useState } from "react";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import Svg from "../common/Svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import TemplateDetailsModal from "../common/TemplateDetailsModal";
import { deleteMetaspace, getTemplates } from "../../actions/metaSpace";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { Formik } from "formik";

import { createExperienceSchema } from "../../Schema/Experience";
import {
  createExperience,
  getExperienceDetail,
  updateExperience,
} from "../../actions/experience";
import ExperienceSubHeader from "./ExperienceSubHeader";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useRef } from "react";

// import { EditText } from 'react-edit-text';
// import 'react-edit-text/dist/index.css';

import Modal from 'react-bootstrap/Modal';

export default function CreateExperience() {

  const navigate = useNavigate();
  let { state } = useLocation();

  const [metaSpaceList, setMetaspaceList] = useState([]);
  const [isFetchingMetaspaces, setIsFetchingMetaspaces] = useState(false);
  const [selectedMetaspace, setSelectedMetaspace] = useState(null);
  const [isCreateingExpereance, setIsCreateingExpereance] = useState(false);
  const [isFetchingDetail, setIsFetchingDetail] = useState(false);
  const [experianceDetail, setExperianceDetail] = useState(null);
  const [formValue, setFormValue] = useState({
    name: "",
    description: "",
    templates: state?.template ? [state.template] : [],
    default_template: "",
    isEnabled: true,
  });
  const [metaspaceListServerParams, setMetaspacelistServerParams] = useState({
    startingLimit: 0,
    resultsPerPage: 100,
    search: "",
    sort_by: "template_name",
    sort_order: "asc",
  });
  const [searchMetaspace, setSearchMetaspace] = useState("");
  const [sortValue, setSortValue] = useState("Sort A-Z");
  const sortingOptions = [
    { id: 1, value: "asc", label: "Sort A-Z" },
    { id: 2, value: "desc", label: "Sort Z-A" },
  ];

  const [isBredcrumbRedirect, setIsBredcrumbRedirect] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedMetaspaceDelete, setSelectedMetaspaceDelete] = useState({});


  let breadcrumbStateData = {
    experienceId: state?.experienceId,
    mode: "createMode",
  };

  const formikRef = useRef();

  const getTemplatData = async (serverParams = metaspaceListServerParams) => {
    setIsFetchingMetaspaces(true);
    try {
      const res = await getTemplates(serverParams);
      if (res.statusCode === 200) {
        setMetaspaceList(res.data);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsFetchingMetaspaces(false);
    }
  };
  let experienceId = null;

  const addExperienceWithBreadcrumb = async (
    data,
    submitFromNav = false,
    path = ""
  ) => {
    let createRes = null;

    if (state && state.experienceId) {
      data.id = state.experienceId;
      createRes = await updateExperience(data);
      experienceId = state.experienceId;
    } else {
      createRes = await createExperience(data);
      experienceId = createRes.data.id;
    }
    state = { experienceId: experienceId, mode: "createMode" };
    breadcrumbStateData = { experienceId: experienceId, mode: "createMode" };
    if (submitFromNav) {
      navigate(path, {
        state: { experienceId: experienceId, mode: "createMode" },
      });
    }
    return createRes;
  };

  const addExperience = async (data) => {
    setIsCreateingExpereance(true);
    try {
      addExperienceWithBreadcrumb(data).then((response) => {
        if (response.statusCode === 200) {
          // toast.success(response.msg);
          navigate("/experiences/create-experience/select-hotspot", {
            state: { experienceId: experienceId, mode: "createMode" },
          });
        } else {
          toast.error(response.msg);
        }
      });
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setIsCreateingExpereance(false);
    }
  };

  const fetchExperienceDetail = async () => {
    setIsFetchingDetail(true);
    let dataToSend = {
      id: state.experienceId,
    };
    try {
      const res = await getExperienceDetail(dataToSend);
      if (res.statusCode === 200) {
        setExperianceDetail(res.data);
        setFormValue({
          ...formValue,
          name: res.data.name,
          description: res.data.description,
          templates: res.data.templates,
          default_template: res.data.default_template,
        });
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.msg);
    } finally {
      setIsFetchingDetail(false);
    }
  };

  const checkFormWithBreadcrumb = (e, path) => {
    e.preventDefault();
    formikRef.current
      .validateForm()
      .then((res) => {
        if (Object.keys(res).length !== 0) {
          formikRef.current.handleSubmit();
        } else {
          let values = formikRef.current.values;
          let templateIds = values.templates.map((e) => e.id);
          let dataToSend = {
            name: values.name,
            description: values.description,
            templates: templateIds,
            default_template: values.templates[0].id,
          };
          addExperienceWithBreadcrumb(dataToSend, true, path);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleSearchMetasapce = (serachValue) => {
    setSearchMetaspace(serachValue);
    setMetaspacelistServerParams({
      ...metaspaceListServerParams,
      search: serachValue,
    });
    getTemplatData({ ...metaspaceListServerParams, search: serachValue });
  };

  const handleSorting = (sortValue) => {
    setMetaspacelistServerParams({ ...metaspaceListServerParams, sort_order: sortValue });
    getTemplatData({ ...metaspaceListServerParams, sort_order: sortValue });
  };

  useEffect(() => {
    if (state && state.experienceId) {
      fetchExperienceDetail();
    }
    if (state && state.templates) {
      setFormValue({ ...formValue, templates: state.templates });
    }
    getTemplatData();
  }, [state]);



  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  }

  const handleShowDeleteModal = (template) => {
    console.log("template", template);
    setSelectedMetaspaceDelete(template);
    setOpenDeleteModal(true);
  }

  const handleDeleteMetaspace = async () => {
    try {
      const res = await deleteMetaspace(
        {
          "template_id": selectedMetaspaceDelete.id,
          "template_build_id": selectedMetaspaceDelete.build_id
        }
      );
      if (res.statusCode === 200) {
        getTemplatData();
        toast.success(res.msg);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    } finally {
      setOpenDeleteModal(false);
    }
  }

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <div className="meeting-header">
              <ul>
                <li>
                  <Link
                    to={"/experiences/create-experience"}
                    state={breadcrumbStateData}
                    content-attr="Create experience"
                    className={"active"}
                  >
                    Create Experience
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/experiences/create-experience/select-hotspot"}
                    state={breadcrumbStateData}
                    content-attr="Select mediaspots"
                    onClick={(e) =>
                      checkFormWithBreadcrumb(
                        e,
                        "/experiences/create-experience/select-hotspot"
                      )
                    }
                  >
                    Select Hotspots
                  </Link>
                </li>
                <li>
                  <Link
                    to={
                      "/experiences/create-experience/select-hotspot/add-annotations"
                    }
                    state={breadcrumbStateData}
                    content-attr="Add Annotations"
                    onClick={(e) =>
                      checkFormWithBreadcrumb(
                        e,
                        "/experiences/create-experience/select-hotspot/add-annotations"
                      )
                    }
                  >
                    Annotations
                  </Link>
                </li>
                <li>
                  <Link
                    to={
                      "/experiences/create-experience/select-hotspot/add-media"
                    }
                    state={breadcrumbStateData}
                    content-attr="Add Media"
                    onClick={(e) =>
                      checkFormWithBreadcrumb(
                        e,
                        "/experiences/create-experience/select-hotspot/add-media"
                      )
                    }
                  >
                    Add Media
                  </Link>
                </li>
                <li>
                  <Link
                    to={
                      "/experiences/create-experience/select-hotspot/add-media/reorder"
                    }
                    content-attr="Reorder"
                    state={breadcrumbStateData}
                    onClick={(e) =>
                      checkFormWithBreadcrumb(
                        e,
                        "/experiences/create-experience/select-hotspot/add-media/reorder"
                      )
                    }
                  >
                    Reorder
                  </Link>
                </li>
              </ul>
            </div>
            <Formik
              innerRef={formikRef}
              initialValues={formValue}
              enableReinitialize
              validationSchema={createExperienceSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                let templateIds = values.templates.map((e) => e.id);
                let dataToSend = {
                  name: values.name,
                  description: values.description,
                  templates: templateIds,
                  default_template: values.templates[0].id,
                };
                addExperience(dataToSend);
                resetForm();
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="page-content-wrap">
                    <div className="heading-section page-heading d-md-flex exp-button-wrapper">
                      <div className="flex-grow-1">
                        <nav
                          aria-label="breadcrumb"
                          className="vmersive-breadcrumbs"
                        >
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to="/experiences">Experiences</Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              Create Experience
                            </li>
                          </ol>
                        </nav>
                        <h1>Create Experience</h1>
                        <p className="pt-2">
                          Here you can create new experiences from scratch using
                          MetaSpaces{" "}
                        </p>
                      </div>

                      <div className="button-wrap mt-3 mt-md-0">
                        <button
                          type="submit"
                          className="btn btn-primary add-btn"
                          disabled={isCreateingExpereance}
                        >
                          {isCreateingExpereance ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            <>
                              Select hotspots <Svg name="right-arrow" />
                            </>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="exp-button-wrapper-outer">
                      <div className="page-wrapper">
                        <div className="new-experience-wrap exp-button-wrapper">
                          <div className="expt-input-wrapper d-md-flex">
                            <h6 className="mb-0 exp-name">Experience name</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info">
                                <h4 className="mb-2 position-relative">
                                  {" "}
                                  {/** Add class error when error occurs */}
                                  <input
                                    type="text"
                                    className="form-control title"
                                    placeholder="Enter your experience name here"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                  />
                                  {errors.name &&
                                    touched.name &&
                                    errors.name && (
                                      <div className="validation-msg">
                                        {errors.name &&
                                          touched.name &&
                                          errors.name}
                                      </div>
                                    )}
                                </h4>

                                <p className="mb-0 position-relative">
                                  {" "}
                                  {/** Add class error when error occurs */}
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Add description"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                  ></textarea>
                                  {errors.description &&
                                    touched.description &&
                                    errors.description && (
                                      <div className="validation-msg">
                                        {errors.description &&
                                          touched.description &&
                                          errors.description}
                                      </div>
                                    )}
                                </p>

                                {/* <h4 className='mb-2'>
                                                        <EditText
                                                            name="expName"
                                                            placeholder="Enter your experience name here"
                                                            inputClassName='heading-input'
                                                        />
                                                    </h4>


                                                    <p className='mb-0'>
                                                        <EditText
                                                            name="expdesc"
                                                            placeholder="Add description"
                                                            inputClassName='heading-input'
                                                        />
                                                    </p> */}
                              </div>
                            </div>
                          </div>

                          {/* <div className="expt-input-wrapper d-md-flex align-items-center">
                                            <h6 className='mb-0'>Experience type</h6>
                                            <div className="flex-grow-1 input-outer">
                                                <div className='checkWrap d-flex flex-wrap'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" defaultValue id="exp1" />
                                                        <label className="form-check-label" htmlFor="exp1">
                                                            Metaverse
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" defaultValue id="exp2" defaultChecked />
                                                        <label className="form-check-label" htmlFor="exp2">
                                                            Immersive
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" defaultValue id="exp3" defaultChecked />
                                                        <label className="form-check-label" htmlFor="exp3">
                                                            Immersive (Self-guided)
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" defaultValue id="exp4" defaultChecked />
                                                        <label className="form-check-label" htmlFor="exp4">
                                                            VR
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div> */}
                          <div className="expt-input-wrapper d-md-flex align-items-center">
                            <h6 className="mb-0">MetaSpaces</h6>
                            <div className="flex-grow-1 input-outer">
                              <div className="inputs-wrap account-info position-relative">
                                <div className="wrppr ">
                                  <div className="placeholder-text">
                                    <p>
                                      {values.templates.length === 0 &&
                                        "Your selected MetaSpaces will appear here"}
                                    </p>
                                  </div>
                                  <div className="chips-wrap d-flex flex-wrap align-items-center">
                                    {values.templates.length > 0 && (
                                      <>
                                        {values.templates.map(
                                          (temp, tempIndex) => (
                                            <span
                                              className="chips d-flex align-items-center"
                                              key={temp.id}
                                            >
                                              <p>
                                                {temp.name ||
                                                  temp.template_name}
                                              </p>
                                              <button
                                                onClick={() => {
                                                  const tempArray =
                                                    values.templates.filter(
                                                      (ele) => {
                                                        return (
                                                          ele.id !== temp.id
                                                        );
                                                      }
                                                    );
                                                  setFieldValue(
                                                    "templates",
                                                    tempArray
                                                  );
                                                }}
                                              >
                                                <Svg name="times-icon" />
                                              </button>
                                            </span>
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                {errors.templates &&
                                  touched.templates &&
                                  errors.templates && (
                                    <div className="validation-msg">
                                      {errors.templates &&
                                        touched.templates &&
                                        errors.templates}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="templates-wrapper">
                          <div className="heading-section page-heading mb-3">
                            <div className="d-flex align-items-center">
                              <h3>Select MetaSpaces </h3>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip>
                                    You can only select metaspaces from one
                                    build
                                  </Tooltip>
                                }
                              >
                                <button className="btn btn-link btn-tooltip p-0 ms-2">
                                  <Svg name="tooltip-icon" />
                                </button>
                              </OverlayTrigger>
                            </div>

                            <p className="pt-2">
                              Choose the Metaspaces which you want to use in
                              your experience. Each MetaSpace has predefined
                              Hotspots and media spots which you can customize
                              for your experience.
                            </p>
                            {/* <div className="d-flex align-items-center pt-2">
                              <Svg name="i-icon" />
                              <p className="flex-grow-1 ps-1">
                                Note: You can only select metaspaces from one
                                build
                              </p>
                            </div> */}
                          </div>
                          <div className="tab-search search-filter d-flex align-items-center">
                            <div className="flex-grow-1 search-filter-padding">
                              <input
                                type="text"
                                className="form-control custom-search-input"
                                placeholder="Search metaspaces"
                                value={searchMetaspace}
                                onChange={(e) => {
                                  handleSearchMetasapce(e.target.value);
                                }}
                              />
                            </div>
                            <div className="drop-wrap d-flex align-items-center">
                              <label>Sort By - </label>
                              <div className="dropdown table-dropdown ques-options">
                                <button
                                  className="btn dropdown-tgl"
                                  type="button"
                                  id="sort-by"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {sortValue}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="sort-by"
                                >
                                  {sortingOptions.map((sort) => (
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      key={sort.id}
                                      onClick={() => {
                                        handleSorting(sort.value);
                                        setSortValue(sort.label);
                                      }}
                                    >
                                      {sort.label}
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          {isFetchingMetaspaces ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            <div className="template-outer-wrapper">
                              {metaSpaceList ? (
                                <>
                                  {metaSpaceList &&
                                    Object.entries(metaSpaceList).map(
                                      (metaSpace, metaSpaceIndex) => (
                                        <div className="mataspaces-listing"
                                          key={metaSpaceIndex}
                                        >
                                          <div className="title-outer">
                                            <h4>{metaSpace[0]} </h4>
                                          </div>
                                          <div className="templates-row-list templates-wrapper">
                                            {Object.values(metaSpace[1]).map(
                                              (space, spaceIndex) => (
                                                <div
                                                  className={`list-wrap 
                                                  template-card d-flex align-items-center 
                                                  ${values.templates.length > 0 && space.id === values.templates[0].id && 'active'} `}
                                                  key={spaceIndex}
                                                >


                                                  <div className="img-wrap">
                                                    <img
                                                      src={space.image}
                                                      className="img-fluid"
                                                      alt={space?.template_name}
                                                    />
                                                  </div>
                                                  <div className="flex-grow-1 text-wrap">
                                                    <div className="d-flex align-items-center">
                                                      <div className="text-inner flex-grow-1">
                                                        <h5 title={space?.template_name} > {space?.template_name} </h5>
                                                        <p>{metaSpace[0]}</p>
                                                      </div>
                                                      <div className="button-wrap">
                                                        <button className="btn btn-primary" type="button"
                                                          onClick={() => {
                                                            setFieldValue(
                                                              "templates",
                                                              [
                                                                space,
                                                              ]
                                                            );
                                                          }}
                                                        >
                                                          Select MetaSpace
                                                        </button>
                                                      </div>
                                                      <div className="action-buttons">
                                                        <OverlayTrigger
                                                          overlay={<Tooltip>Edit</Tooltip>}
                                                        >
                                                          <button className="btn btn-action"
                                                            onClick={() => navigate("/metaspaces/edit-Metaspace", {
                                                              state: { template_id: space.id, template_build_id: space.build_id }
                                                            })}
                                                          >
                                                            <Svg name="icon-edit" />
                                                          </button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                          overlay={<Tooltip>View Details</Tooltip>}
                                                        >
                                                          <button
                                                            type="button"
                                                            className="btn btn-action"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#modalDetails"
                                                            srno="1"
                                                            value="1"
                                                            onClick={() => {
                                                              setSelectedMetaspace(
                                                                space
                                                              );
                                                            }}
                                                          >
                                                            <Svg name="eye-icon" />
                                                          </button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                          overlay={<Tooltip>Delete</Tooltip>}
                                                        >
                                                          <button className="btn btn-action" data-bs-toggle="modal"
                                                            data-bs-target="#deleteModal"
                                                            id="delete-button"
                                                            type="button"
                                                            onClick={() => handleShowDeleteModal(space)}
                                                          >
                                                            <Svg name="icon-delete" />
                                                          </button>
                                                        </OverlayTrigger>
                                                      </div>
                                                    </div>
                                                  </div>


                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}
                                </>
                              ) : (
                                <>No Data Found</>
                              )}
                              {/* <div className="temp-wrapper disabled">
                        <h4 className="title">Smart city iOT build</h4>
                        <div className="templates-row row">
                          <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                            <div className="template-card">
                              <div className="img-wrap">
                                <img
                                  src={template1}
                                  className="img-fluid"
                                  alt=""
                                />
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="template1"
                                  name="chooseTemplate"
                                />
                                <div className="d-flex align-items-center button-wrap">
                                  <button
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalDetails"
                                    srno="1"
                                    value="1"
                                  >
                                    View MetaSpace
                                  </button>
                                </div>
                              </div>
                              <div className="text-wrapper">
                                <h5>Meeting with suburban client</h5>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                            <div className="template-card">
                              <div className="img-wrap">
                                <img
                                  src={template2}
                                  className="img-fluid"
                                  alt=""
                                />
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="template1"
                                  name="chooseTemplate"
                                />

                                <div className="d-flex align-items-center button-wrap">
                                  <button
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalDetails"
                                    srno="1"
                                    value="1"
                                  >
                                    View MetaSpace
                                  </button>
                                </div>
                              </div>
                              <div className="text-wrapper">
                                <h5>Meeting with suburban client</h5>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                            <div className="template-card">
                              <div className="img-wrap">
                                <img
                                  src={template3}
                                  className="img-fluid"
                                  alt=""
                                />
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="template1"
                                  name="chooseTemplate"
                                />

                                <div className="d-flex align-items-center button-wrap">
                                  <button
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalDetails"
                                    srno="1"
                                    value="1"
                                  >
                                    View MetaSpace
                                  </button>
                                </div>
                              </div>
                              <div className="text-wrapper">
                                <h5>Meeting with suburban client</h5>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-3 col-xl-4 col-md-6 mb-3">
                            <div className="template-card">
                              <div className="img-wrap">
                                <img
                                  src={template4}
                                  className="img-fluid"
                                  alt=""
                                />
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="template1"
                                  name="chooseTemplate"
                                />

                                <div className="d-flex align-items-center button-wrap">
                                  <button
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalDetails"
                                    srno="1"
                                    value="1"
                                  >
                                    View MetaSpace
                                  </button>
                                </div>
                              </div>
                              <div className="text-wrapper">
                                <h5>Meeting with suburban client</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div
        className="customvar-modal modal fade modalDetails"
        id="modalDetails"
        tabIndex={-1}
        aria-labelledby="modalDetailsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-details-width">
          <div className="modal-content">
            <TemplateDetailsModal data={selectedMetaspace} />
          </div>
        </div>
      </div>

      <Modal className="customvar-modal modal custom-modal fade deleteModal" show={openDeleteModal} onHide={handleCloseDeleteModal}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className={
                  "formvawrapper delete-modal-wrap modal-setting-medium"
                }
              >
                <div className={"formstyle p-md-2"}>
                  <div className="delete-body-wrap delete-language">
                    <div className="heading">
                      <h3>Delete  Metaspace</h3>
                    </div>

                    <div className="text-normal">
                      <p>
                        Are you sure you want to delete this Metaspace?{" "}
                        <strong>{selectedMetaspaceDelete.template_name}</strong>
                      </p>
                    </div>

                    <div className="button-wrap d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-delete"
                        onClick={handleDeleteMetaspace}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="btn btn-bordered color-cancel ms-3"
                        onClick={handleCloseDeleteModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
