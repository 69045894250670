import axios from "../utils/axios";
import { BASE_URL } from "./urls";

export const getTemplates = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/template/getVmersiveTemplates`,
    data: payload
  });

  return response.data;
};

export const getTemplateDetail = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/template/getTemplateDetail`,
    data: payload,
  });

  return response.data;
};
export const createMetaspace = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/template/addMetaspace`,
    data: payload,
  });

  return response.data;
};

export const editMetaspace = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "PUT",
    url: `${BASE_URL}/template/editmetaspace`,
    data: payload,
  });

  return response.data;
};

export const getTemplateDetailById = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    url: `${BASE_URL}/template/getTemplateById`,
    data: payload,
  });

  return response.data;
};

export const deleteMetaspace = async (payload) => {
  const token = localStorage.getItem("token");
  const response = await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
    url: `${BASE_URL}/template/deletemetaspace`,
    data: payload,
  });

  return response.data;
}