import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "../common/DashboardHeader";
import DashboardSidebar from "../common/DashboardSidebar";
import ScheduleHeader from "./ScheduleHeader";
import Svg from "../common/Svg";
import CreatableSelect from "react-select/creatable";
import { Formik } from "formik";
import { createMeetingSchema } from "../../Schema/Meeting";
import { toast, ToastContainer } from "react-toastify";
import {
  createMeeting,
  editMeeting,
  getMeetingDetailEdit,
  getTimezone,
} from "../../actions/meeting";

const moment = require("moment");

export default function Schedule() {
  const navigate = useNavigate();

  const { state } = useLocation();

  const formikRef = useRef();

  const [editStartTimeValue, setEditStartTimeValue] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timeDuration, setTimeDuration] = useState(null);
  const [isCreateingMeeting, setIsCreateingMeeting] = useState(false);
  const [meetingDetail, setMeetingDetail] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [createdMeetingId, setCreatedMeetingId] = useState("");
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [endTimeIntervals, setEndTimeIntervals] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    value: "",
    label: "Select Timezone",
  });

  function intervals(startString, endString) {
    var start = moment(startString, "YYYY-MM-DD hh:mm a");
    var end = moment(endString, "YYYY-MM-DD hh:mm a");

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
      result.push({
        value: current.format("HH:mm:ss"),
        label: current.format("hh:mm A"),
      });
      current.add(15, "minutes");
    }

    setTimeIntervals(result);
  }

  const customNoOptionsText = (inputValue) => {
    if (inputValue !== "")
      return "No matching options found. Please Enter starts at time";
  };

  const endTimeOptions = (selectedtime) => {
    const startIndex = timeIntervals.findIndex(
      (time) => time.value === selectedtime
    );
    const tempArray = [];
    if (startIndex !== -1) {
      for (
        let i = startIndex + 1;
        i < timeIntervals.length + startIndex + 1;
        i++
      ) {
        const newIndex =
          i >= timeIntervals.length ? i - timeIntervals.length : i;
        tempArray.push(timeIntervals[newIndex]);
        setEndTimeIntervals(tempArray);
      }
    }
  };

  const addMeeting = async (data, callfrom) => {
    setIsCreateingMeeting(true);
    try {
      const res = await createMeeting(data);
      if (res.statusCode === 200) {
        // toast.success(res.msg);
        setIsCreateingMeeting(false);
        setCreatedMeetingId(res.data.id);
        if (callfrom === "") {
          navigate("/events/select-experience", { state: res.data.id });
        }
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const updateMeeting = async (data, callfrom) => {
    setIsCreateingMeeting(true);
    try {
      const res = await editMeeting(data);
      if (res.statusCode === 200) {
        // toast.success(res.msg);
        setIsCreateingMeeting(false);
        setCreatedMeetingId(res.data.id);
        if (callfrom === "") {
          navigate("/events/select-experience", { state: res.data.id });
        }
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const getMeetingDetail = async () => {
    setIsFetching(true);
    let dataToSend = {
      id: state,
    };
    try {
      const res = await getMeetingDetailEdit(dataToSend);
      if (res.statusCode === 200) {
        setMeetingDetail(res.data[0]);
        let startTime = moment(res.data[0].event_date_time)
          // .utc()
          .format("HH:mm:ss");
        setEditStartTimeValue(startTime);
        let endTime = moment(res.data[0].event_end_date_time)
          // .utc()
          .format("HH:mm:ss");

        const s = moment(res.data[0].event_date_time);
        const e = moment(res.data[0].event_end_date_time);
        const duration = moment.duration(e.diff(s));

        const hours = duration.hours() > 0 ? `${duration.hours()}H` : "";
        const minutes = duration.minutes() > 0 ? `${duration.minutes()}M` : "";

        setTimeDuration(`${hours} ${minutes}`);

        let sDate = moment(res.data[0].event_date_time).format("YYYY-MM-DD");
        let eDate = moment(res.data[0].event_end_date_time).format(
          "YYYY-MM-DD"
        );
        setStartDate(sDate);
        setEndDate(eDate);

        if (formikRef.current) {
          formikRef.current.setFieldValue("id", res.data[0].id);
          formikRef.current.setFieldValue("name", res.data[0].event_name);
          formikRef.current.setFieldValue(
            "description",
            res.data[0].event_description
          );
          formikRef.current.setFieldValue("start_date", sDate);
          formikRef.current.setFieldValue("end_date", eDate);
          formikRef.current.setFieldValue("start_time", startTime);

          formikRef.current.setFieldValue("end_time", endTime);
          formikRef.current.setFieldValue(
            "timezone",
            res.data[0].event_timezone
          );
        }
        setIsFetching(false);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {}
  };

  const getTimezones = async () => {
    return new Promise(async (resolve, reject) => {
      const res = await getTimezone();
      if (res.statusCode === 200) {
        let data = res.data;
        let tempTimeZones = [];
        Object.keys(data).map((ele) => {
          tempTimeZones.push({
            value: data[ele].value,
            label: data[ele].text,
          });
          setTimeZones(tempTimeZones);
        });
        resolve(res);
      }
    });
  };

  const addOrUpdateMeeting = () => {
    const date = moment(startDate).format("YYYY-MM-DD");
    if (formikRef.current) {
      let values = formikRef.current.values;
      if (values.id !== "") {
        let editDataToSend = {
          id: values.id,
          name: values.name,
          description: values.description,
          start_date_time: date + " " + values.start_time,
          timezone: values.timezone,
          end_date_time: date + " " + values.end_time,
        };
        updateMeeting(editDataToSend, "headerChange");
      } else {
        let dataToSend = {
          name: values.name,
          description: values.description,
          start_date_time: date + " " + values.start_time,
          timezone: selectedTimeZone.value,
          end_date_time: date + " " + values.end_time,
        };
        addMeeting(dataToSend, "headerChange");
      }
    }
  };

  const getDetails = async () => {
    await getTimezones();
    intervals("2022-02-20 12:00:00 AM", "2022-02-20 23:45:00 PM");
    if (state) {
      getMeetingDetail();
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    endTimeOptions(editStartTimeValue);
  }, [editStartTimeValue]);

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="d-flex flex-column main-outer-wrapper main-site-wrapper ">
        <DashboardHeader />
        <div id="viewport" className="d-flex flex-grow-1">
          <DashboardSidebar />
          <div className="flex-grow-1 page-content-flex">
            <ScheduleHeader
              schedule
              eventId={createdMeetingId ? createdMeetingId : state}
              onChangeStep={() => {
                addOrUpdateMeeting();
              }}
            />
            <div className="page-content-wrap">
              <Formik
                innerRef={formikRef}
                initialValues={{
                  id: "",
                  name: "",
                  description: "",
                  start_date: startDate,
                  end_date: endDate,
                  start_time: "",
                  timezone: "",
                  end_time: "",
                  isEnabled: true,
                }}
                validationSchema={createMeetingSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  const startdate = moment(startDate).format("YYYY-MM-DD");
                  const enddate = moment(endDate).format("YYYY-MM-DD");
                  let dataToSend = {
                    name: values.name,
                    description: values.description,
                    start_date_time: startdate + " " + values.start_time,
                    timezone: selectedTimeZone.value,
                    end_date_time: enddate + " " + values.end_time,
                  };
                  let editDataToSend = {
                    id: values.id,
                    name: values.name,
                    description: values.description,
                    start_date_time: startdate + " " + values.start_time,
                    timezone: values.timezone,
                    end_date_time: enddate + " " + values.end_time,
                  };
                  if (meetingDetail) {
                    updateMeeting(editDataToSend, "");
                  } else {
                    addMeeting(dataToSend, "");
                  }
                  if (!isCreateingMeeting) {
                    resetForm();
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className="heading-section page-heading d-md-flex align-items-center">
                        <div className="flex-grow-1">
                          <h1>Schedule Immersive(Guided) Event</h1>
                                <p>Hosted experience with single player video collaboration</p>
                        </div>
                        <div className="button-wrap mt-3 mt-md-0">
                          <button
                            type="submit"
                            className="btn btn-primary add-btn"
                            disabled={isCreateingMeeting}
                          >
                            {isCreateingMeeting ? (
                              <>
                                <span
                                  className="white-spinner spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </>
                            ) : (
                              <>
                                Select experience
                                <Svg name="right-arrow" />
                              </>
                            )}{" "}
                          </button>
                        </div>
                      </div>
                      {isFetching ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        <div className="schedule-form account-info">
                          <div className="meeting-card pt-0">
                            <div className="row">
                              <div className="col-xl-7">
                                <div className="mb-3 meeting-title position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Add title"
                                    name="name"
                                    defaultValue={values.name}
                                    onChange={handleChange}
                                  />
                                  {errors.name &&
                                    touched.name &&
                                    errors.name && (
                                      <div className="validation-msg">
                                        {errors.name &&
                                          touched.name &&
                                          errors.name}
                                      </div>
                                    )}
                                </div>
                                <div className="mb-3 position-relative">
                                  <textarea
                                    className="form-control"
                                    placeholder="Add description"
                                    style={{ height: 60 }}
                                    name="description"
                                    defaultValue={values.description}
                                    onChange={handleChange}
                                  />
                                  {errors.description &&
                                    touched.description &&
                                    errors.description && (
                                      <div className="validation-msg">
                                        {errors.description &&
                                          touched.description &&
                                          errors.description}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="meeting-card border-0 date-time-column">
                            <div className="meeting-heading">Date & Time </div>
                            <div className="row">
                              <div className="col-xl-8">
                                <div className="field-wrapper icon-field-wrapper">
                                  <div className="form-group d-flex align-items-lg-center align-items-start">
                                    <div className="icon-wrap">
                                      <Svg name="clock" />
                                    </div>
                                    <div className="media-body flex-grow-1 d-md-flex align-items-lg-center align-items-start date-time-flex">
                                      <div className="date-time d-lg-flex flex-grow-1">
                                        <div className="date position-relative">
                                          <span className="text pe-2 mob-view">
                                            Date
                                          </span>
                                          <ReactDatePicker
                                            selected={
                                              new Date(values.start_date)
                                            }
                                            minDate={moment().toDate()}
                                            onChange={(date) => {
                                              setFieldValue("start_date", date);
                                              setStartDate(date);
                                            }}
                                          />
                                          {errors.date &&
                                            touched.date &&
                                            errors.date && (
                                              <div className="validation-msg">
                                                {errors.date &&
                                                  touched.date &&
                                                  errors.date}
                                              </div>
                                            )}
                                        </div>
                                        <div className="d-flex align-items-center my-2 my-md-0 cstom-select">
                                          {/* <span className="text pe-2">
                                            Starts at
                                          </span> */}
                                          <div className="time position-relative">
                                            <CreatableSelect
                                              value={
                                                timeIntervals
                                                  ? timeIntervals.find(
                                                      (option) =>
                                                        option.value ===
                                                        values.start_time
                                                    )
                                                  : ""
                                              }
                                              options={timeIntervals}
                                              onChange={(e) => {
                                                endTimeOptions(e.value);
                                                setFieldValue(
                                                  "start_time",
                                                  e.value
                                                );
                                              }}
                                            />
                                            {errors.start_time &&
                                              touched.start_time &&
                                              errors.start_time && (
                                                <div className="validation-msg">
                                                  {errors.start_time &&
                                                    touched.start_time &&
                                                    errors.start_time}
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                        <span className="text pe-2">to</span>
                                        <div className="date position-relative">
                                          <span className="text pe-2 mob-view">
                                            Date
                                          </span>
                                          <ReactDatePicker
                                            selected={new Date(values.end_date)}
                                            minDate={moment().toDate()}
                                            onChange={(date) => {
                                              setFieldValue("end_date", date);
                                              setEndDate(date);
                                            }}
                                          />
                                          {errors.date &&
                                            touched.date &&
                                            errors.date && (
                                              <div className="validation-msg">
                                                {errors.date &&
                                                  touched.date &&
                                                  errors.date}
                                              </div>
                                            )}
                                        </div>
                                        <div className="d-flex align-items-center cstom-select">
                                          <div className="d-flex align-items-center my-2 my-md-0 cstom-select">
                                            <div className="time position-relative">
                                              <CreatableSelect
                                                value={
                                                  endTimeIntervals
                                                    ? endTimeIntervals.find(
                                                        (option) =>
                                                          option.value ===
                                                          values.end_time
                                                      )
                                                    : ""
                                                }
                                                noOptionsMessage={
                                                  customNoOptionsText
                                                }
                                                options={endTimeIntervals}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "end_time",
                                                    e.value
                                                  );
                                                }}
                                              />
                                              {errors.end_time &&
                                                touched.end_time &&
                                                errors.end_time && (
                                                  <div className="validation-msg">
                                                    {errors.end_time &&
                                                      touched.end_time &&
                                                      errors.end_time}
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        {timeDuration && (
                                          <span className="text pe-2">
                                            {timeDuration}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="meeting-card border-0 pb-0 pt-0 timezne-wrpr">
                            <div className="meeting-heading">Timezone </div>
                            <div className="field-wrapper icon-field-wrapper">
                              <div className="row">
                                <div className="col-xl-7">
                                  <div className="form-group d-flex align-items-lg-center align-items-start">
                                    <div className="icon-wrap">
                                      <Svg name="timer-icon" />
                                    </div>
                                    <div className="media-body flex-grow-1 d-md-flex align-items-lg-center align-items-start date-time-flex">
                                      <div className="date-time d-lg-flex flex-grow-1">
                                        <div className="d-flex align-items-center  timezone-dropdown dropdown">
                                          <span className="text pe-2 mob-view">
                                            Time zone
                                          </span>
                                          <div className="timezone ps-1 cstom-select position-relative">
                                            <CreatableSelect
                                              value={
                                                timeZones
                                                  ? timeZones.find(
                                                      (option) =>
                                                        option.value ===
                                                        values.timezone
                                                    )
                                                  : ""
                                              }
                                              placeholder={"Select Timezone"}
                                              options={timeZones}
                                              onChange={(e) => {
                                                setSelectedTimeZone(e);
                                                setFieldValue(
                                                  "timezone",
                                                  e.value
                                                );
                                              }}
                                            />
                                            {errors.timezone &&
                                              touched.timezone &&
                                              errors.timezone && (
                                                <div className="validation-msg">
                                                  {errors.timezone &&
                                                    touched.timezone &&
                                                    errors.timezone}
                                                </div>
                                              )}
                                            {/* <button
                                        className="btn btn-timezone"
                                        type="button"
                                        id="dropdownTimezone"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        {" "}
                                        Time zone{" "}
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownTimezone"
                                      >
                                        <li>
                                          <span className="dropdown-item">
                                            (GMT+04:30) Kabul
                                          </span>
                                        </li>
                                        <li>
                                          <span className="dropdown-item">
                                            (GMT+05:00) Ekaterinburg
                                          </span>
                                        </li>
                                        <li>
                                          <span className="dropdown-item">
                                            (GMT+05:00) Islamabad, Karachi,
                                            Tashkent
                                          </span>
                                        </li>
                                        <li>
                                          <span className="dropdown-item">
                                            (GMT+05:30) Chennai, Kolkata, Mumbai,
                                            New Delhi
                                          </span>
                                        </li>
                                        <li>
                                          <span className="dropdown-item">
                                            (GMT+05:45) Kathmandu
                                          </span>
                                        </li>
                                        <li>
                                          <span className="dropdown-item">
                                            (GMT+06:00) Astana, Dhaka
                                          </span>
                                        </li>
                                      </ul> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
