import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AccordianTextModal = ({
    teleportData,
    onSave,
    show,
    handleClose,
}) => {

    const [headText, setHeadText] = useState("");
    const [accordians, setAccordians] = useState([{ headingText: "", text: "" }]);

    useEffect(() => {

        setHeadText(teleportData?.widget_details?.headText || "");
        setAccordians(teleportData?.widget_details?.accordians || [{ headingText: "", text: "" }]);

    }, [teleportData]);

    const handleSave = () => {
        const widgetDetails = {
            headText: headText,
            accordians: accordians,
        }
        onSave(widgetDetails);
        handleClose();
    }

    const handleAddAccordian = () => {
        const accordian = accordians.length;
        if (accordian > 4) return;
        setAccordians([...accordians, { headingText: "", text: "" }]);
    }

    const handleChangeSliderData = (index, data, key) => {
        setAccordians(preState => {
            const newState = [...preState];
            newState[index][key] = data;
            return newState;
        });
    }

    const handleRemoveSlider = (index) => {
        if (accordians.length === 1) return;
        setAccordians(preState => {
            const newState = [...preState];
            newState.splice(index, 1);
            return newState;
        });
    }


    return (
        <div>
            <Modal show={show === "accordianTextModal"}
                onHide={handleClose}
                className="customvar-modal modal fade fileUpload uploadMedia"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-details-width"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="widget-code">
                        <div className="heading">
                            <h4>Edit Widget</h4>
                        </div>
                        <div className="d-flex text-image-widget text-video-widget align-items-start">
                            <div className="img-text-wrap">
                                <div className="accordion-wrapper">
                                    <div className="heading">
                                        <h4>{headText}</h4>
                                    </div>
                                    <div className="accordion" id="accordionWidget">
                                        {accordians.map((accordian, index) =>
                                            <div className="accordion-item" key={index}>
                                                <h2 className="accordion-header" id={`accordionheading${index}`}>
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target={`#accordionCollapse${index}`} aria-expanded="true"
                                                        aria-controls={`#accordionCollapse${index}`}
                                                    >
                                                        {accordians[index].headingText}
                                                    </button>
                                                </h2>
                                                <div id={`accordionCollapse${index}`} className="accordion-collapse collapse show"
                                                    aria-labelledby={`accordionheading${index}`} data-bs-parent="#accordionWidget"
                                                >
                                                    <div className="accordion-body">
                                                        <div dangerouslySetInnerHTML={{ __html: accordians[index].text }} className='editor'></div>
                                                    </div>
                                                </div>
                                            </div>

                                        )}
                                    </div>

                                </div>


                            </div>
                            <div className="flex-grow-1 widget-fields accordion-view">
                                <div className="form-group mb-3">
                                    <div className="form-group mb-3">
                                        <div className="label">Heading</div>
                                        <input type="text" name="" className="form-control" placeholder="Enter your heading here"
                                            value={headText}
                                            onChange={(e) => setHeadText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="slide-wrapper">
                                    {accordians.map((accordian, index) =>
                                        <div className="slide-list" key={index}>
                                            <div className="slide-title">
                                                Accordion {index + 1}
                                            </div>
                                            <div className="slide-inner">
                                                <div className="form-group mb-3">
                                                    <div className="label">Heading</div>
                                                    <input type="text" name="" className="form-control"
                                                        placeholder="Enter your heading here"
                                                        value={accordian.headingText}
                                                        onChange={(e) => handleChangeSliderData(index, e.target.value, "headingText")}
                                                    />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <div className="label">Paragraph</div>
                                                    <div className="editor-wrapper">
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={accordian.text}
                                                            config={{
                                                                removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table", "TableToolbar"]
                                                            }}
                                                            onChange={(event, editor) => {
                                                                let data = editor.getData();
                                                                handleChangeSliderData(index, data, "text");
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}

                                </div>

                                <div className="add-button mb-3">
                                    <button className="add-slide-button"
                                        onClick={handleAddAccordian}
                                        disabled={accordians.length > 4}
                                    >
                                        Add Accordion
                                    </button>
                                </div>

                                <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                                    <button type="button" className="btn btn-primary" onClick={handleSave}>Save widget</button>
                                    <button type="button" className="btn btn-bordered color-cancel ms-3" onClick={handleClose}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default AccordianTextModal