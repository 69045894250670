import React, { useState, useEffect } from 'react'
import Svg from "../../common/Svg";
import video from "../../../assets/images/small.mp4";
import Modal from 'react-bootstrap/Modal';
import FileUploadWidget from '../add-media/FileUploadWidget';

const PdfTextModal = ({
    teleportData,
    onSave,
    show,
    handleClose,
}) => {

    const [text, setText] = useState("");
    const [primBtnText, setPrimBtnText] = useState("");
    const [primBtnUrl, setPrimBtnUrl] = useState("");
    const [secBtnText, setSecBtnText] = useState("");
    const [secBtnUrl, setSecBtnUrl] = useState("");
    const [widgetFile, setWidgetFile] = useState({});
    const [file, setFile] = useState(null);

    useEffect(() => {
        setPrimBtnText(teleportData?.widget_details?.prim_btn_text || "");
        setPrimBtnUrl(teleportData?.widget_details?.prim_btn_url || "");
        setSecBtnText(teleportData?.widget_details?.sec_btn_text || "");
        setSecBtnUrl(teleportData?.widget_details?.sec_btn_url || "");
        setText(teleportData?.widget_details?.text || "");
        setWidgetFile(teleportData?.widget_details?.file || {});
        setFile(teleportData?.widget_details?.file || null);

        console.log(teleportData?.widget_details?.file);
    }, [teleportData]);

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSave = () => {
        const widgetDetails = {
            text,
            prim_btn_text: primBtnText,
            prim_btn_url: primBtnUrl,
            sec_btn_text: secBtnText,
            sec_btn_url: secBtnUrl,
            file: widgetFile,
        }
        onSave(widgetDetails);
        handleClose();
    }

    return (
        <div>
            <Modal show={show === "pdfModal"}
                onHide={handleClose}
                className="customvar-modal modal fade fileUpload uploadMedia"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-details-width"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="widget-code">
                        <div className="heading">
                            <h4>Edit Widget</h4>
                        </div>
                        <div className="d-flex text-image-widget pdf viewer align-items-start">
                            <div className="img-text-wrap">
                                Please add PDF Preview Here
                            </div>
                            <div className="flex-grow-1 widget-fields">
                                {/* <div className="file-upload form-group mb-3">
                                    <div className="label">Upload an image</div>
                                    <input className="form-control" type="file" id="formFile" />
                                    <div className="form-text">Dimensions: 400 x 600px | Dimensions: 400 x 600px Supported format: PNG,JPEG,JPG</div>
                                </div> */}

                                <FileUploadWidget
                                    currentMediaSpotId={null}
                                    selectedSpotAreaId={null}
                                    currentMediaSpotData={widgetFile}
                                    changeMediaSpotData={(data) => {
                                        setWidgetFile(data);
                                        console.log(data);
                                    }}
                                    closeModal={handleClose}
                                    isOpenModal={true}
                                />

                                <div className="button-wrapper d-flex justify-content-end btn-160 pt-2">
                                    <button type="button" className="btn btn-primary" onClick={handleSave} >Save widget</button>
                                    <button type="button" className="btn btn-bordered color-cancel ms-3" onClick={handleClose}>Cancel</button>
                                </div>

                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default PdfTextModal